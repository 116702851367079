import React, { useState, useRef } from 'react';

// libraries
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import { VscBell } from 'react-icons/vsc';
import { AnimatePresence, motion } from 'framer-motion';
import { useQuery } from '@apollo/react-hooks';
import { sumBy } from 'lodash';
import clsx from 'clsx';

// global components
import Text from 'components/Text/Text';
import Title from 'components/Title/Title';
import useClickOutSide from 'components/hooks/useClickOutSide';

// local components
// import News from './News';
import Validations from './Validations';

// data
import TOTALS from './totalNotifications.graphql';
import VALIDATIONS from './validations.graphql';

const notifTypes = [/* 'news' */ 'validations'];
const totalsKeys = {
  validations: 'getNbItemsToValidate',
  // news: 'getNewsToValidate',
};

const components = {
  // news: ({ t, data }) => <News t={t} />,
  validations: ({ t, validationItems, validationTotal, loadingValidations }) => (
    <Validations
      t={t}
      validationItems={validationItems}
      validationTotal={validationTotal}
      loadingValidations={loadingValidations}
    />
  ),
};

const NotificationButtons = ({ t, contentType, type, handleNav, totals, validationItems }) => {
  const dynamicKey = totalsKeys[type];

  if (!Object.values(validationItems).some((item) => item.enabled === true)) return null;

  return (
    <button
      type="button"
      className={clsx(
        'pb-2 border-0 border-b-4 border-solid transition-colors ',
        contentType === type
          ? 'border-primary-500 text-primary-500'
          : 'border-transparent text-gray-600 hover:text-gray-700 hover:border-gray-700',
      )}
      onClick={() => handleNav(type)}
    >
      <div className="flex flex-row gap-2">
        <Text className="font-semibold uppercase">{t(`notifications.${type}`)}</Text>
        <span className="h-5 bg-primary-500 text-white text-xs font-medium px-2.5 py-0.5 rounded-full self-center">
          {totals[dynamicKey]?.total || 0}
        </span>
      </div>
    </button>
  );
};

const NavigationNotifications = ({
  t,
  setContentType,
  contentType,
  totals,
  loading,
  validationItems,
}) => {
  const handleNav = (type) => {
    setContentType(type);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex gap-4 w-full border-b border-solid border-gray-400">
        {notifTypes.map((type) => {
          return loading ? (
            <div key={type} className="w-1/4 bg-gray-300 animate-pulse h-5 rounded mb-2" />
          ) : (
            <NotificationButtons
              key={type}
              t={t}
              contentType={contentType}
              type={type}
              handleNav={handleNav}
              totals={totals}
              validationItems={validationItems}
            />
          );
        })}
      </div>
    </div>
  );
};

const Notifications = ({ t }) => {
  const { data, loading } = useQuery(TOTALS);
  const { data: validationData, loading: loadingValidations } = useQuery(VALIDATIONS);
  const [contentType, setContentType] = useState('validations');
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useClickOutSide(setOpen, ref);

  const validationItems = validationData?.getNbItemsToValidate?.items || {};
  const validationTotal = validationData?.getNbItemsToValidate?.total || 0;

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const totals = sumBy(Object.values(data || {}), 'total');

  return (
    <div ref={ref} className="flex">
      <button type="button" onClick={handleClick} className="relative">
        <VscBell className="text-xl" />
        {totals > 0 && (
          <span className="absolute w-2.5 h-2.5 rounded-full top-0 right-0 bg-primary-500 ring-2 ring-white" />
        )}
      </button>

      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="w-screen max-w-lg right-0 p-4 absolute"
          >
            <div className="bg-white shadow-2xl shadow-neutral-500 rounded p-6 space-y-4">
              <Title size="h4">{t('notifications.title')}</Title>
              <NavigationNotifications
                t={t}
                setContentType={setContentType}
                contentType={contentType}
                totals={data}
                loading={loading}
                validationItems={validationItems}
                loadingValidations={loadingValidations}
              />
              <div className="hover:decoration-current">
                {components[contentType]({
                  t,
                  validationItems,
                  validationTotal,
                  loadingValidations,
                })}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

NavigationNotifications.defaultProps = {
  totals: {
    getNbItemsToValidate: {
      total: 0,
    },
  },
  loading: true,
};

NotificationButtons.defaultProps = {
  totals: {
    getNbItemsToValidate: {
      total: 0,
    },
  },
};

Notifications.propTypes = {
  t: PropTypes.func.isRequired,
};

NavigationNotifications.propTypes = {
  t: PropTypes.func.isRequired,
  contentType: PropTypes.string.isRequired,
  setContentType: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    getNbItemsToValidate: PropTypes.shape({
      total: PropTypes.number,
    }),
  }),
  loading: PropTypes.bool,
  validationItems: PropTypes.shape({
    amplify: PropTypes.shape({
      enabled: PropTypes.bool,
      count: PropTypes.number,
    }),
    converse_comment: PropTypes.shape({
      enabled: PropTypes.bool,
      count: PropTypes.number,
    }),
    order: PropTypes.shape({
      enabled: PropTypes.bool,
      count: PropTypes.number,
    }),
    publish: PropTypes.shape({
      enabled: PropTypes.bool,
      count: PropTypes.number,
    }),
  }).isRequired,
};

NotificationButtons.propTypes = {
  t: PropTypes.func.isRequired,
  contentType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  handleNav: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    getNbItemsToValidate: PropTypes.shape({
      total: PropTypes.number,
    }),
  }),
  validationItems: PropTypes.shape({
    amplify: PropTypes.shape({
      enabled: PropTypes.bool,
      count: PropTypes.number,
    }),
    converse_comment: PropTypes.shape({
      enabled: PropTypes.bool,
      count: PropTypes.number,
    }),
    order: PropTypes.shape({
      enabled: PropTypes.bool,
      count: PropTypes.number,
    }),
    publish: PropTypes.shape({
      enabled: PropTypes.bool,
      count: PropTypes.number,
    }),
  }).isRequired,
};

export default withTranslation('ui')(Notifications);
