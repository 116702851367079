// note, the HTML5 spec allows for an `<a>` tag to wrap a whole section, such as a `<div>` tag.
// source: https://www.w3.org/TR/2011/WD-html5-20110525/text-level-semantics.html#the-a-element

import React, { useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { getCurrentBreakpoint } from 'utils/getCurrentBreakpoint';

import Text from 'components/Text';
import Link from 'next/link';
import { BsPlus } from 'react-icons/bs';

export const PrimaryMenuItem = ({ title, subtitle, path, IconComponent }) => {
  return (
    <Link href={path || '/'} className="-m-3" passHref>
      <a
        href="/"
        className="flex items-center space-x-3 rounded-lg bg-white p-3 transition duration-150 ease-in-out hover:bg-gray-50"
      >
        {IconComponent && (
          <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-primary-500 text-white sm:h-12 sm:w-12">
            <p className="text-xl text-white">{IconComponent}</p>
          </div>
        )}
        <div className="space-y-1">
          {title && (
            <Text className="font-sans text-base font-medium text-gray-900 line-clamp-1">
              {title}
            </Text>
          )}
          {subtitle && <Text className="text-sm text-gray-600 line-clamp-1">{subtitle}</Text>}
        </div>
      </a>
    </Link>
  );
};

export const SecondaryMenuItem = ({ title, items, close }) => {
  return (
    <div className={clsx('contents', 'cursor-pointer')}>
      {items.length > 0 && (
        <div className="space-y-1">
          {title && (
            <h3 className="font-secondary text-xs font-bold tracking-wide text-gray-900">
              {title}
            </h3>
          )}
          <ul className="space-y-2">
            {items.map(({ title: itemTitle, path, onClick }, idx) => (
              <li className="truncate text-base" key={`${title}_${itemTitle}`} title={title}>
                {onClick ? (
                  <a
                    href={path}
                    onClick={(...args) => {
                      close();
                      onClick(...args);
                    }}
                    className="font-secondary text-sm font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-700"
                  >
                    {itemTitle}
                  </a>
                ) : (
                  <Link href={path || '/'} className="-m-3" passHref>
                    <a
                      href="/"
                      className="font-secondary text-sm font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-700"
                    >
                      {itemTitle}
                    </a>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export const TertiaryMenuItem = ({ title, path, onClick }) => {
  const TertiaryMenuItemNode = (
    <a
      href={path || '/'}
      className="group flex items-center gap-2 font-secondary text-base font-medium text-gray-700 transition hover:text-gray-900"
      onClick={onClick}
    >
      <BsPlus className="text-xl" />
      <span className="text-sm">{title}</span>
    </a>
  );

  if (onClick) return TertiaryMenuItemNode;

  return (
    <Link href={path || '/'} passHref>
      {TertiaryMenuItemNode}
    </Link>
  );
};

export const MegaMenu = ({
  renderButton,
  parentNavEl,
  primaryNavigation = [],
  secondaryNavigation = [],
  tertiaryNavigation = [],
}) => {
  const [visibility, setVisibility] = useState(false);

  const anyNavigation =
    primaryNavigation.length > 0 || secondaryNavigation.length > 0 || tertiaryNavigation.length > 0;

  const breakpoint = getCurrentBreakpoint();

  const secondaryNavigationColumns = () => {
    if (secondaryNavigation.length >= 5) {
      if (['lg', 'xl', '2xl', '3xl'].includes(breakpoint)) return 5;

      return 4;
    }

    if (secondaryNavigation.length <= 2) return 2;

    return secondaryNavigation.length;
  };

  return (
    <motion.div
      onHoverStart={() => setVisibility(true)}
      onHoverEnd={() => setVisibility(false)}
      className="space-y-2"
    >
      {renderButton(parentNavEl ?? {})}

      <AnimatePresence>
        {visibility && anyNavigation && (
          <motion.div
            key={parentNavEl.i18n_value}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              ease: [0.6, 0.01, -0.05, 0.95],
              duration: 0.3,
            }}
            className="absolute left-0 top-full z-10 w-full rounded-lg"
            layout
            layoutId="mega"
          >
            <div className="container mx-auto w-fit space-y-6 overflow-hidden rounded-lg bg-white shadow-xl">
              {primaryNavigation.length > 0 && (
                <div
                  className={clsx(
                    'relative grid gap-2 bg-white px-5 pt-6 sm:px-8 lg:grid-cols-2',
                    secondaryNavigation.length === 0 && tertiaryNavigation.length === 0 && 'pb-6',
                  )}
                >
                  {primaryNavigation.map(({ title, subtitle, path, IconComponent }) => (
                    <PrimaryMenuItem
                      key={title}
                      title={title}
                      subtitle={subtitle}
                      path={path}
                      IconComponent={IconComponent}
                    />
                  ))}
                </div>
              )}
              {secondaryNavigation.length > 0 && (
                <div
                  className={clsx(
                    'grid grid-cols-4 gap-4 gap-y-6 bg-white px-8 xl:grid-cols-5',
                    primaryNavigation.length === 0 && 'pt-6',
                    tertiaryNavigation.length === 0 && 'pb-6',
                  )}
                  style={{
                    gridTemplateColumns: `repeat(${secondaryNavigationColumns()}, minmax(0, 1fr))`,
                  }}
                >
                  {secondaryNavigation.map(({ title, items }) => (
                    <SecondaryMenuItem
                      key={title}
                      title={title}
                      items={items}
                      close={() => setVisibility(false)}
                    />
                  ))}
                </div>
              )}
              {tertiaryNavigation.length > 0 && (
                <div className="flex gap-8 bg-gray-100 p-5 sm:p-8">
                  {tertiaryNavigation.map(({ title, path, onClick }) => (
                    <TertiaryMenuItem key={title} title={title} path={path} onClick={onClick} />
                  ))}
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const navItemProps = PropTypes.shape({
  title: PropTypes.string,
  subtitle: PropTypes.string,
  path: PropTypes.string,
});

MegaMenu.propTypes = {
  renderButton: PropTypes.func.isRequired,
  parentNavEl: PropTypes.shape({
    i18n_value: PropTypes.string,
    path: PropTypes.string,
    active_paths: PropTypes.arrayOf(PropTypes.string),
    feature: PropTypes.string,
    permission: PropTypes.string,
    children: PropTypes.arrayOf(navItemProps),
    userRole: PropTypes.string,
  }).isRequired,
  primaryNavigation: PropTypes.arrayOf(navItemProps),
  secondaryNavigation: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(navItemProps),
    }),
  ),
  tertiaryNavigation: PropTypes.arrayOf(navItemProps),
};

MegaMenu.defaultProps = {
  primaryNavigation: [],
  secondaryNavigation: [],
  tertiaryNavigation: [],
};

PrimaryMenuItem.propTypes = {
  ...navItemProps,
  // IconComponent is a react-icon component
  // eslint-disable-next-line react/forbid-prop-types
  IconComponent: PropTypes.object.isRequired,
};

SecondaryMenuItem.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(navItemProps),
  close: PropTypes.func,
};

SecondaryMenuItem.defaultProps = {
  title: null,
  items: [],
  close: () => null,
};

TertiaryMenuItem.defaultProps = {
  onClick: null,
};

TertiaryMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
