const blockStyles = {
  'header-one': 'text-lg font-title',
  'header-two': 'text-base font-title',
  'header-three': 'text-sm uppercase font-title',
  unstyled: 'text-sm font-body',
  'unordered-list-item': 'text-sm font-body',
  'ordered-list-item': 'text-sm font-body',
};

const customBlockStyles = (contentBlock) => {
  const type = contentBlock.getType();

  return blockStyles[type];
};

export default customBlockStyles;
