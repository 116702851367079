import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';
import moment from 'moment';
import { BsChevronRight, BsChevronLeft, BsEye } from 'react-icons/bs';
import { GiCircle, GiPlainCircle } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';
import { useQuery, useMutation } from '@apollo/react-hooks';

import Modal from 'components/Modal';
import Title from 'components/Title/Title';
import Text from 'components/Text/Text';
import DisplayRichText from 'components/DisplayRichText/DisplayRichText';
import Button from 'components/Button/Button';
import Image from 'components/Image/Image';
import Tooltip from 'components/Tooltip/Tooltip';
import { getCurrentBreakpoint } from 'utils/getCurrentBreakpoint';

import GET_USER_UNPRESENTED_NEWS from './graphql/getUserUnpresentedNews.graphql';
import MARK_NEWS_AS_PRESENTED from './graphql/markNewsAsPresented.graphql';

// Accept news/lang for preview need
function NewsModal({ news, lang, visible, close }) {
  const {
    t,
    i18n: { language },
  } = useTranslation('ui');

  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);

  const [markNewsAsPresented] = useMutation(MARK_NEWS_AS_PRESENTED);
  const { data } = useQuery(GET_USER_UNPRESENTED_NEWS, {
    skip: !!news,
    onCompleted: (d) => {
      if (d?.getUserUnpresentedNews?.length) {
        markNewsAsPresented({
          variables: { ids: data.getUserUnpresentedNews.map(({ id }) => id) },
        });
      }
    },
    fetchPolicy: 'network-only',
  });

  const allNews = news || data?.getUserUnpresentedNews || [];

  if (allNews.length === 0) return null;

  const currentNews = allNews[currentNewsIndex];
  const content =
    currentNews.contents.find((c) => c.lang === (lang || language)) ||
    currentNews.contents.find((c) => c.lang === 'en');
  const breakpoint = getCurrentBreakpoint() || '_';
  const isMobile = ['_', 'sm'].includes(breakpoint);

  const imageContainer = (
    <div className="flex items-center justify-center select-none">
      <Image className="max-h-36 md:max-h-max" src={currentNews.imageUrl} alt={content.title} />
    </div>
  );

  return (
    <Modal
      close={close}
      visible={visible}
      outerClassName="!pt-0 !mt-0 flex justify-center items-center"
      dialogClassName="!h-auto max-w-[95%] relative flex flex-col"
      className="flex flex-col"
    >
      <button
        type="button"
        onClick={close}
        className="cursor-pointer absolute top-6 right-6 bg-white/10 rounded-full z-10 flex items-center justify-center w-8 h-8"
      >
        <AiOutlineClose className="text-white" />
      </button>
      {isMobile && (
        <div className="bg-primary-500 min-h-20">
          {currentNews.imageUrl && (
            <div className="relative left-8 top-3 z-10">{imageContainer}</div>
          )}
        </div>
      )}
      <div
        className="flex flex-col h-fit-content drop-shadow-xl max-w-full w-[52.5rem] px-6 md:px-11 py-6"
        style={{
          background: isMobile
            ? `white`
            : `linear-gradient(70deg, white 56%, var(--primary-500) 56.1%)`,
        }}
      >
        <div className="relative flex flex-col space-y-12 md:space-y-0 md:flex-row gap-x-5">
          <div className="flex items-center md:min-w-[49%] md:max-w-[49%]">
            <div className="w-full">
              <Title className="uppercase text-primary-500" size="s2">
                {t('news-modal.title')}
              </Title>
              <div className="flex items-center space-x-2 mb-6">
                <Text className="!text-gray-600 !font-medium" size="p2">
                  {moment(content.releasedAt).format('D MMMM')}
                </Text>
                <Tooltip
                  position="right"
                  text={t(
                    `news-modal.view-tooltip.${
                      currentNews.recipient.roles.includes('local') ? 'user' : 'admin'
                    }`,
                  )}
                >
                  <BsEye />
                </Tooltip>
              </div>
              <Title className="mb-3" size="h3">
                {content.title}
              </Title>
              <div className="h-1 w-12 bg-primary-500 mb-5" />
              <div className="mb-10 h-24 overflow-y-auto">
                <DisplayRichText content={content.description} />
              </div>
              {content.cta?.link?.length > 0 && content.cta?.text?.length > 0 && (
                <div className="flex justify-end">
                  <Button
                    onClick={() => window.open(content.cta.link)}
                    appearance="ghost"
                    icon={<BsChevronRight className="ml-2" />}
                    iconPosition="right"
                  >
                    {content.cta.text}
                  </Button>
                </div>
              )}
            </div>
          </div>
          {!isMobile && currentNews.imageUrl && imageContainer}
          {allNews.length > 1 && (
            <div className="md:absolute md:bottom-3 flex items-center justify-center md:justify-start space-x-6">
              <BsChevronLeft
                className="cursor-pointer"
                onClick={() =>
                  setCurrentNewsIndex(
                    currentNewsIndex === 0 ? allNews.length - 1 : currentNewsIndex - 1,
                  )
                }
              />
              <div className="flex items-center space-x-2.5">
                {allNews.map(({ id }, i) =>
                  i === currentNewsIndex ? (
                    <GiCircle
                      key={id}
                      size={10}
                      className="cursor-pointer text-primary-500"
                      onClick={() => setCurrentNewsIndex(i)}
                    />
                  ) : (
                    <GiPlainCircle
                      key={id}
                      size={8}
                      className="cursor-pointer text-primary-500"
                      onClick={() => setCurrentNewsIndex(i)}
                    />
                  ),
                )}
              </div>
              <BsChevronRight
                className="cursor-pointer"
                onClick={() =>
                  setCurrentNewsIndex(
                    currentNewsIndex === allNews.length - 1 ? 0 : currentNewsIndex + 1,
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

NewsModal.defaultProps = {
  news: null,
  lang: null,
};

NewsModal.propTypes = {
  close: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  lang: PropTypes.string,
  news: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      imageUrl: PropTypes.string,
    }),
  ),
};

export default NewsModal;
