/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { get } from 'lodash';
import { BsEye, BsArrowLeftRight } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { parseCookies } from 'nookies';
import { withTranslation } from 'i18n';
import { useRouter } from 'next/router';

import Text from 'components/Text/Text';
import Title from 'components/Title/Title';
import Radio from 'components/Radio/Radio';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import Pagination from 'components/Pagination';
import Input from 'components/Input/Input';
import Logo from 'components/Logo/Logo';
import useRole from 'components/hooks/useRole';
import Select from 'components/Select/Select';
import useSuperAdminLogin from 'components/hooks/useSuperAdminLogin';
import useDebounce from 'components/hooks/useDebounce';

import SET_DEFAULT_STORE_ID from 'graphql/mutations/setDefaultStoreId.graphql';
import SALESPOINTS from 'graphql/queries/salesPointsList.graphql';
import CLIENTS_OF_USER from 'graphql/queries/clientsOfUser.graphql';
import USER_SELECTED_SALESPOINT from 'graphql/queries/userSelectedSalesPoint.graphql';
import ROLES from 'graphql/queries/roles.graphql';
import { useAuthContext } from '../../../../utils/AuthContext';

const UserStores = ({ t, onClose, setOpenMultiOrga }) => {
  const router = useRouter();
  const { setSalespointContext } = useAuthContext();
  const isSuperAdmin = Boolean(parseCookies().superAdminJWT);

  const [params, setParams] = useState({ offset: 0, limit: 5 });
  const [inputSearch, setInputSearch] = useState('');
  const debounceSearch = useDebounce(inputSearch, 666);
  const [selectedSalesPoint, setSelectedSalesPoint] = useState(null);
  const [userRole] = useRole({ skip: !isSuperAdmin });
  const superAdminLogin = useSuperAdminLogin();

  const { data: dataRoles } = useQuery(ROLES, { skip: !isSuperAdmin });
  const { data, loading: loadingAllSalesPoints } = useQuery(SALESPOINTS, {
    variables: { ...params, filter: { keyword: debounceSearch, state: 'enabled' } },
  });
  const { data: dataOrga, loading: loadingOrga } = useQuery(CLIENTS_OF_USER);
  const { loading: loadingSelectedSalesPoint } = useQuery(USER_SELECTED_SALESPOINT, {
    onCompleted: (dataSelectedSalesPoint) => {
      setSelectedSalesPoint(dataSelectedSalesPoint?.me.selectedSalesPoint); // TODO to remove post context implementation
      setSalespointContext(dataSelectedSalesPoint?.me.selectedSalesPoint);
    },
  });

  const loading = loadingOrga || loadingAllSalesPoints || loadingSelectedSalesPoint;
  const clients = dataOrga?.clientsOfUser || [];
  const [setDefaultStoredId] = useMutation(SET_DEFAULT_STORE_ID);
  const allStores = get(data, 'salesPoints.salesPoints', []);
  const storeCount = get(data, 'salesPoints.count', 0);
  const pageCount = Math.ceil(storeCount / params.limit);
  const { client_id: clientId } = parseCookies();
  const clientName = clients.find((client) => client.clientId === clientId)?.name || '';

  const sendChangeSalespointId = async (salespointId) =>
    window.gtag('event', 'login', {
      event_category: 'login',
      event_action: 'loginSuccess',
      dimension1: clientId,
      dimension3: salespointId,
    });

  const handleClick = async () => {
    if (!selectedSalesPoint) return;

    await setDefaultStoredId({
      variables: {
        storeId: selectedSalesPoint.id,
      },
      refetchQueries: ['userDetail'],
      skip: !selectedSalesPoint.id,
    });

    onClose();
    try {
      await sendChangeSalespointId(selectedSalesPoint.id);
    } catch (_) {
      router.reload();
    }

    router.reload();
  };

  return (
    <div className="grid max-h-screen place-items-center content-center">
      <div className="max-h-fit rounded bg-white p-6">
        <div className="flex w-full items-center justify-between border-b border-solid border-gray-300 pb-4">
          <Title size="h4" className="block w-full flex-1 text-center">
            {t('user-stores.my-establishments')}
          </Title>
          <button type="button" onClick={onClose} className="">
            <i className="icon-close" />
          </button>
        </div>
        <div className="flex flex-col gap-4 pt-4">
          {isSuperAdmin && (
            <div className="space-y-2">
              <Title size="h5">Super admin role :</Title>
              <Select
                options={
                  dataRoles?.roles.map((roleToLogin) => ({
                    label: roleToLogin.name,
                    value: roleToLogin,
                  })) || []
                }
                value={userRole?.name}
                onChange={(roleToLogin) =>
                  superAdminLogin({ roleSlug: roleToLogin.slug, clientId })
                }
              />
            </div>
          )}
          {!loadingOrga && clients.length > 1 && (
            <div>
              <Title size="h5">{t('user-organisations.organisation')}</Title>
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-4">
                  <Logo size="small" />
                  <Text>{clientName}</Text>
                </div>
                <div
                  className="flex cursor-pointer items-center gap-4"
                  onClick={() => setOpenMultiOrga(true)}
                >
                  <BsArrowLeftRight />
                  <Text className="font-semibold">
                    {t('user-organisations.change-organisation')}
                  </Text>
                </div>
              </div>
            </div>
          )}
          <div className="space-y-2">
            <div className="flex items-center gap-1">
              <Title size="h5">{t('user-stores.selected-establishment')}</Title>
              <Button
                appearance="ghost"
                icon={<BsEye className="mr-1" />}
                onClick={() => {
                  router.push(`/store/${selectedSalesPoint?.id}`);
                  onClose();
                }}
              >
                {t('user-stores.seen-establishment')}
              </Button>
            </div>
            {!loading && selectedSalesPoint ? (
              <>
                <Text>{selectedSalesPoint.name}</Text>
                <Text>
                  {selectedSalesPoint.location
                    ? `${selectedSalesPoint.location.address}, ${selectedSalesPoint.location.postalCode} ${selectedSalesPoint.location.city}`
                    : ''}
                </Text>
              </>
            ) : (
              <Text>{t('user-stores.no-selected-establishment')}</Text>
            )}
          </div>
          <div className="max-h-screen space-y-4">
            <div className="space-y-2">
              <Title size="h5">{t('user-stores.my-establishments')}</Title>
              <div>
                <Text size="p2">{t('user-stores.choose-establishment-description')}</Text>
              </div>
            </div>
            <Input
              value={inputSearch || ''}
              icon="icon-search"
              placeholder={t('choose-store')}
              onChange={(e) => {
                setParams({ offset: 0, limit: 5 });
                setInputSearch(e.target.value);
              }}
            />
            {loading ? (
              <Loader type="ThreeDots" loading={loading} />
            ) : (
              <>
                {pageCount === 0 ? (
                  <div className="flex w-full flex-col items-center justify-center gap-2">
                    <i className="icon-store-fill text-4xl" />
                    <Text size="p1" className="store-status">
                      {t('common:no-stores')}
                    </Text>
                  </div>
                ) : (
                  <>
                    <div className="delivery-stores">
                      <div className="space-y-2">
                        {allStores.map((store) => (
                          <div
                            key={store.id}
                            className="flex items-center gap-2"
                            onClick={() => setSelectedSalesPoint(store)}
                          >
                            <Radio
                              id={store.id}
                              name="stores"
                              checked={selectedSalesPoint?.id === store.id}
                              value={store.id}
                            />
                            <div className="flex w-full items-center justify-between">
                              <Text>{store.name}</Text>
                              <Text>
                                {store.location
                                  ? `${store.location.address}, ${store.location.city}, ${store.location.country}`
                                  : ''}
                              </Text>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            <div className="flex justify-center">
              <Pagination
                params={params}
                setParams={setParams}
                pageCount={pageCount}
                className="delivery-stores__pagination"
                inputSearch={inputSearch}
              />
            </div>
            {pageCount !== 0 && (
              <div className="flex w-full justify-end gap-4">
                <Button className="cancel-button" appearance="ghost" onClick={onClose}>
                  <span>{t('cancel')}</span>
                </Button>
                <Button
                  className="confirm-button"
                  disabled={!selectedSalesPoint}
                  onClick={handleClick}
                >
                  {t('confirm')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

UserStores.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setOpenMultiOrga: PropTypes.func.isRequired,
};

export default withTranslation('cart')(UserStores);
