/* eslint-disable prettier/prettier */
import React, { createContext, useCallback, useState } from 'react';
import { isEmpty, get } from 'lodash';
import { useRouter } from 'next/router';
import parseUrlFilters from 'utils/parseUrlFilters';

const initialFilters = [];

export const FiltersSearchContext = createContext({
  filters: initialFilters,
  setFilter: () => null,
  clearFilters: () => null,
  setFilters: () => null,
  setMediaFilter: () => null,
  setMultipleMediaFilter: () => null,
});

export function withSearchContext(Component) {
  return ({ ...props }) => {
    const { query } = useRouter();

    const initFilters = parseUrlFilters(query);

    const [filters, setFilters] = useState(initFilters);

    const clearFilters = (filtersToClear = []) => {
      if (isEmpty(filtersToClear)) {
        return setFilters(initialFilters);
      }

      const newFilters = filters.filter(({ id }) => !filtersToClear.includes(id));

      return setFilters(newFilters);
    };

    const setFilter = useCallback(
      (id, value, type) => {
        const { oldFilterValue, filtersValue } = filters.reduce(
          (acc, { id: filterId, ...rest }) => {
            if (id === filterId) {
              acc.oldFilterValue = { id, ...rest };

              return acc;
            }

            acc.filtersValue.push({ id: filterId, ...rest });

            return acc;
          },
          { oldFilterValue: null, filtersValue: [] },
        );
        if (type === 'options') {
          const oldOptions = get(oldFilterValue, 'options', []);
          const optionsExist = oldOptions.find((option) => option === value);
          const newOptions = optionsExist
            ? oldOptions.filter((option) => option !== value)
            : [...oldOptions, value];

          const newFilters = [...filtersValue];
          if (!isEmpty(newOptions)) {
            newFilters.push({ ...oldFilterValue, id, options: newOptions });
          }

          setFilters(newFilters);
        } else {
          setFilters([...filtersValue, { ...oldFilterValue, id, [type]: value }]);
        }
      },
      [filters],
    );

    const setMediaFilter = useCallback(
      (option, filterType) => {
        const newFilters = filters.filter(
          ({ optionID, type }) => !(optionID === option && type === filterType),
        );

        if (newFilters.length === filters.length)
          newFilters.push({ optionID: option, type: filterType });
        setFilters(newFilters);
      },
      [filters],
    );

    const setMultipleMediaFilter = useCallback(
      (filtersOptions) => {
        const newFilters = filters.filter(
          ({ optionID, type }) =>
            !filtersOptions.some(
              ({ optionID: filterOption, type: filterType }) =>
                optionID === filterOption && type === filterType,
            ),
        );
        filtersOptions.forEach((filter) => {
          if (
            !filters.some(
              (newFilter) =>
                filter.optionID === newFilter.optionID && filter.type === newFilter.type,
            )
          )
            newFilters.push(filter);
        });
        setFilters(newFilters);
      },
      [filters],
    );

    return (
      <FiltersSearchContext.Provider
        value={{
          filters,
          setFilter,
          clearFilters,
          setFilters,
          setMediaFilter,
          setMultipleMediaFilter,
        }}
      >
        <Component
          filters={filters}
          setFilter={setFilter}
          setMediaFilter={setMediaFilter}
          setMultipleMediaFilter={setMultipleMediaFilter}
          clearFilters={clearFilters}
          setFilters={setFilters}
          {...props}
        />
      </FiltersSearchContext.Provider>
    );
  };
}
