import React, { useMemo } from 'react';

// libraries
import 'draft-js/dist/Draft.css';
import PropTypes from 'prop-types';
import { convertFromRaw, Editor, EditorState } from 'draft-js';

import Text from 'components/Text/Text';
import { Text as FlexText } from 'flex';
import { customBlockStyles, customStyleMap } from 'components/RichTextEditor/utils';
import { createLinkDecorator } from 'components/RichTextEditor/components/Link';
import CustomFieldsPropType from 'components/CustomField/helpers/CustomFieldsPropType';

const DisplayRichText = ({ content, basicDisplay, flex, className, orderedCustomFields }) => {
  const decorator = createLinkDecorator();

  const editorState = useMemo(() => {
    if (!content) return null;

    // Try catch to avoid errors with JSON.parse on old values being strings
    try {
      // check if existing customField in url given to rich text editor and replace them
      const jsonContent = JSON.parse(content);

      const url = jsonContent?.entityMap?.['0']?.data?.url;

      if (url) {
        const regex = /{_([^{}]+)_}/g;
        const modifiedUrl = url.replace(regex, (match, toFind) => {
          const withSlug = orderedCustomFields.find(
            (customField) => customField.definition.slug === toFind,
          );

          return withSlug && withSlug.textValue !== null ? withSlug.textValue : ' ';
        });

        if (modifiedUrl !== url) {
          jsonContent.entityMap['0'].data.url = modifiedUrl;
        }
      }

      return EditorState.createWithContent(convertFromRaw(jsonContent), decorator);
    } catch (e) {
      return EditorState.createWithText(content, decorator);
    }
  }, [content, decorator]);

  if (!editorState) return null;

  if (basicDisplay) {
    return (
      <Text className={className}>{editorState?.getCurrentContent()?.getPlainText() || ''}</Text>
    );
  }

  if (flex) {
    return (
      <FlexText className={className}>
        {editorState?.getCurrentContent()?.getPlainText() || ''}
      </FlexText>
    );
  }

  return (
    <Editor
      blockStyleFn={customBlockStyles}
      customStyleMap={customStyleMap}
      editorState={editorState}
      readOnly
    />
  );
};

DisplayRichText.defaultProps = {
  basicDisplay: false,
  flex: false,
  className: '',
  orderedCustomFields: [],
};

DisplayRichText.propTypes = {
  content: PropTypes.string.isRequired,
  basicDisplay: PropTypes.bool,
  flex: PropTypes.bool,
  className: PropTypes.string,
  orderedCustomFields: CustomFieldsPropType,
};

export default DisplayRichText;
