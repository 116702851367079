/* eslint-disable indent */
import tw from 'tailwind-styled-components';

export const Wrapper = tw.div``;

export const Navigation = tw.header`
  flex justify-between w-full items-center px-4 xl:px-8 bg-white z-50 fixed h-[4.5rem] border-gray-300 border-solid border-b
`;

export const NavItemsGroup = tw.div`
  flex h-full gap-4 md:gap-0
  items-center
  ${(p) => p.$hideOnMd && 'hidden lg:flex'}
`;

export const NavItem = tw.a`
  sm:mx-1 md:mx-1.5 text-sm xl:mx-3
  min-w-max
  flex items-center font-primary
  transition hover:cursor-pointer hover:text-primary-500
  border-b-4 border-solid border-transparent
  ${(p) => p.$hover && 'hover:border-primary-500'}
  ${(p) => p.$active && 'border-primary-500 text-primary-500'}
  ${(p) => p.$responsive && 'lg:hidden'}
  ${(p) => p.$hideOnXs && 'hidden lg:flex'}
  ${(p) => p.$isText && 'py-0.5 lg:py-2'}
  ${(p) => p.$isOneVision && 'uppercase font-bold'}
`;

export const Content = tw.div`
  flex relative overflow-hidden h-[calc(100vh-4.5rem)]
`;

export const ViewWrapper = tw.section`pt-[4.5rem] fixed inset-0 overflow-y-auto overflow-x-hidden`;

export const View = tw.div`
  min-h-full flex justify-between flex-col max-h-[calc(100vh-4.5rem)]
`;

export const MobileSideNavigation = tw.nav`
  overflow-hidden flex-col absolute z-50 top-0 left-0 h-full w-min bg-white shadow-lg transition
  transform -translate-x-64
  lg:min-w-max lg:static lg:inset-auto lg:shadow-none lg:hidden
  ${(p) => p.$open && '!translate-x-0'}
`;

export const MobileLogout = tw.div`
  absolute bottom-0 px-4 py-6 z-30 w-full font-primary uppercase text-xs text-gray-500
  border-t border-solid border-gray-300
  transition hover:text-white hover:bg-primary-500 hover:cursor-pointer hover:border-primary-500
`;

export const SideNavigation = tw.nav`
  w-72 overflow-x-hidden overflow-y-auto hidden lg:flex lg:flex-col bg-slate-50 h-screen fixed z-10
`;

export const SideNavItem = tw.a`
  w-full px-3 py-4 font-primary text-sm
  flex justify-between items-center gap-4
  transition hover:cursor-pointer hover:bg-primary-100
  ${(p) => p.$active && !p.$offset && '!bg-primary-100 border-primary-500'}
  ${(p) => p.$offset && 'ml-3'}
  ${(p) => (p.$offset && p.$active ? '!border-primary-500 text-primary-500' : '')}
  ${(p) => p.$hideOnXs && 'hidden lg:flex'}
`;

export const SideNavLevelSlider = tw.div`
  flex
`;

export const SideNavLevelHolder = tw.div`
  w-64 overflow-hidden flex-1
`;

export const SwipingNavItem = tw.div`w-64 flex-shrink-0`;

export const Overlay = tw.div`
  absolute w-full h-full bg-black opacity-30 z-10
`;

export const Badge = tw.div`
  absolute -right-1 lg:-right-0.5 -top-1 lg:-top-2 rounded-full bg-primary-500 text-white
  z-30 w-4 h-4 lg:w-5 lg:h-5 text-xs shadow flex items-center justify-center
`;
