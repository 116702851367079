function parseUrlFilters({
  type,
  sort,
  keyword,
  campaigns,
  price,
  newProducts,
  offset,
  limit,
  folder,
  category,
  ...rest
}) {
  const filters = [];
  if (price) {
    const min = parseInt(price.split(':')[0], 10) || 0;
    const max = parseInt(price.split(':')[1], 10) || 99999;
    filters.push({
      id: 'price_range',
      range: { min, max },
    });
  }

  if (newProducts) filters.push({ id: 'new_products', checkbox: true });
  if (keyword) filters.push({ id: 'keyword', keyword });
  if (campaigns) filters.push({ id: 'campaigns', campaigns: [campaigns] });
  if (category) filters.push({ id: 'category', category });

  const customFilters = Object.entries({ ...rest }).filter(([key]) => key !== 'id');
  if (customFilters.length > 0) {
    customFilters.forEach(([key, value]) => {
      filters.push({ id: key, options: value.split(':') });
    });
  }

  return filters;
}

export default parseUrlFilters;
