import styled from 'styled-components';

const PaginationWrapper = styled.div`
  display: flex;

  .pagination {
    display: flex;
    align-items: center;
    padding: 2px 7px;

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      min-height: 24px;
      width: 24px;
      min-width: 24px;
      margin: 0 12px 0 0;
      color: ${({ theme }) => theme.colors.text500};
      font-family: var(--secondary-font), sans-serif;
      font-size: 16px;
      line-height: 25px;
      font-weight: 500;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        min-height: 24px;
        width: 24px;
        min-width: 24px;
        border-radius: 100%;
      }
    }

    .active {
      background-color: var(--primary-500);
      color: ${({ theme }) => theme.colors.white};
      padding: 0 7px;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      line-height: 2px;
    }

    .disabled {
      display: none;
    }
  }
`;

export default PaginationWrapper;
