import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ src, layout, alt, objectFit, className, priority, onError, onLoad, ...rest }) => {
  if (layout === 'fill') {
    return (
      <div className={`absolute block overflow-hidden inset-0 box-border m-0 ${className}`}>
        <img
          src={src}
          alt={alt}
          className="absolute inset-0 box-border m-0 block w-0 h-0 min-w-full max-w-full min-h-full max-h-full"
          style={{ objectFit }}
          decoding="async"
          loading="lazy"
          onError={onError}
          onLoad={onLoad}
          {...rest}
        />
      </div>
    );
  }

  return (
    <img
      src={src}
      alt={alt}
      className={layout === 'fill-crop' ? 'max-h-[inherit] w-full' : className}
      style={{ objectFit }}
      decoding="async"
      loading="lazy"
      onError={onError}
      onLoad={onLoad}
      {...rest}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  layout: PropTypes.string,
  alt: PropTypes.string.isRequired,
  objectFit: PropTypes.string,
  className: PropTypes.string,
  priority: PropTypes.bool,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
};

Image.defaultProps = {
  layout: null,
  objectFit: null,
  className: '',
  priority: false,
  onError: () => {},
  onLoad: () => {},
};

export default Image;
