import React, { useState, useEffect, useContext } from 'react';

// global components
import Logo from 'components/Logo/Logo';
import { Toaster } from 'react-hot-toast';
import ModalMultiOrga from 'components/ModalMultiOrga/ModalMultiOrga';
import useSignout from 'components/hooks/useSignout';
import { FiltersSearchContext } from 'components/hooks/FiltersContext';
import QaReportButton from 'components/QaReportButton/QaReportButton';

// libraries
import Link from 'next/link';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { useRouter } from 'next/router';
import { motion, AnimatePresence } from 'framer-motion';
import { withTranslation } from 'i18n';
import { useQuery } from '@apollo/react-hooks';
import { flow } from 'lodash';
import { parseCookies } from 'nookies';
import {
  BsList,
  BsShopWindow,
  BsChevronRight,
  BsChevronLeft,
  BsChevronDown,
  BsBoxArrowRight,
  BsArrowLeftRight,
  BsCheckCircle,
  BsXCircle,
  BsPerson,
  BsCart,
} from 'react-icons/bs';
import { CgSpinner } from 'react-icons/cg';
import gql from 'graphql-tag';
import clsx from 'clsx';

// data
import CLIENTS_OF_USER from 'graphql/queries/clientsOfUser.graphql';
import useProviderType from 'components/hooks/useProviderType';
import { PencilIcon, ShoppingCartIcon } from '@heroicons/react/24/outline';
import { Dropdown, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'flex';
import { MdLogout } from 'react-icons/md';
import { RiBillLine } from 'react-icons/ri';
import { navigation, actions, checkFeatureForNavigation } from './navigation.js';

// local components
import UserStores from './components/UserStores';
import Notifications from './components/Notifications';
import { MegaMenu } from './components/MegaMenu';
import NewsModal from './components/NewsModal/NewsModal';

// style
import * as UI from './index.style';
import Text from '../Text/Text';

export const mainUiModale = { organizations: null, stores: null };

const ovChartsUrl = {
  grouperenault: 'https://brand.renault.com/',
  dacia: 'https://www.brandhub.groupe.renault.com/home',
};

const USER_DETAILS = gql`
  query meUI {
    me {
      id
      selectedSalesPointId
      salesPoints {
        id
      }
      role {
        id
        permissions
        slug
        name
        absoluteSlug
      }
      client {
        id
        clientId
        f
        shopOptions {
          categories {
            enabled
            showAllProductsLinkEnabled
          }
        }
      }
      providerInfo {
        name
        slug
        stripePayment
      }
    }
  }
`;

// fetch and calculate cart item quantity
const cart = gql`
  query cart {
    cart {
      userId
      items {
        productId
        quantity
      }
    }
  }
`;

const GET_PARTNER_AUTH_BRIDGE_LIST = gql`
  query getPartnerAuthBridgeList {
    getPartnerAuthBridgeList {
      label
      slug
    }
  }
`;

export const GET_PRODUCT_CATEGORIES_MENU = gql`
  query UI_getProductCategoriesMenu($bypassSelectedSalespoint: Boolean) {
    getProductCategoriesMenu(bypassSelectedSalespoint: $bypassSelectedSalespoint) {
      id
      title
      children {
        id
        title
      }
    }
  }
`;

const isChildrenActive = (currentPath, children, parent) =>
  currentPath.startsWith(children.path) &&
  parent.children
    .filter((child) => child.path !== children.path)
    .every(
      (navChild) =>
        !currentPath.includes(navChild.path) || navChild.path.length <= children.path.length,
    );

const Interface = ({ children, t }) => {
  const { clearFilters, setFilters } = useContext(FiltersSearchContext);
  const signout = useSignout();

  // ready router and current route
  const { asPath, pathname } = useRouter();
  const router = useRouter();
  const destructuredUrl = asPath
    .split('/')
    .filter((n) => n)
    .map((m) => `/${m}`);
  const [root] = destructuredUrl;

  // define current navigation or action object
  let currentRoute = {};
  const currentNavRoute = navigation.filter((i) =>
    [i.path, ...i.active_paths].some((p) => root.startsWith(p)),
  )[0];

  const currentActionsRoute = actions.filter((i) =>
    i.active_paths ? [i.path, ...i.active_paths].some((p) => p.startsWith(root)) : undefined,
  );

  if (currentNavRoute) {
    currentRoute = currentNavRoute;
  }

  if (Object.keys(currentActionsRoute).length > 0) {
    [currentRoute] = currentActionsRoute;
  }

  // various visiblity states
  const [sideNavVisibility, setsideNavVisibility] = useState(false);
  const [storeSelectModalVisibility, setStoreSelectModalVisibility] = useState(false);
  const [openMultiOrga, setOpenMultiOrga] = useState(false);
  mainUiModale.organizations = setOpenMultiOrga;
  mainUiModale.stores = setStoreSelectModalVisibility;
  // manage level 2 and 3 of the mobile side nav's
  const [l2, setL2] = useState(null);
  const [l3, setL3] = useState(null);
  const [navOffset, setnavOffset] = useState(16);
  useEffect(() => {
    if (navOffset !== -32) {
      setnavOffset(navOffset - 16);
    }
  }, [l2, l3]);

  const { data: userDetails } = useQuery(USER_DETAILS, {
    fetchPolicy: 'cache-and-network',
  });
  const { data: dataClients } = useQuery(CLIENTS_OF_USER);

  const clientCategoriesShopOptions = userDetails?.me?.client?.shopOptions?.categories || {};

  const { data: getProductCategoriesMenuData } = useQuery(GET_PRODUCT_CATEGORIES_MENU, {
    skip: !clientCategoriesShopOptions.enabled,
  });

  const { data: partnerAuthBridgeList } = useQuery(GET_PARTNER_AUTH_BRIDGE_LIST, {
    fetchPolicy: 'cache-and-network',
  });

  partnerAuthBridgeList?.getPartnerAuthBridgeList.forEach(({ slug, label }) => {
    const newPath = `/auth-bridges/redirect?slug=${slug}`;
    const existingItem = navigation.find(
      (item) => item.i18n_value === label && item.path === newPath,
    );

    if (!existingItem) {
      navigation.push({
        i18n_value: label,
        customName: label,
        path: newPath,
        active_paths: [],
        newTab: true,
      });
    }
  });

  const clientProductMenuCategories = (
    getProductCategoriesMenuData?.getProductCategoriesMenu || []
  ).map((c) => ({
    title: c.title,
    items: c.children.map((child) => ({
      ...child,
      onClick: (ev) => {
        ev.preventDefault();
        setFilters([{ id: 'category', category: child.id }]);

        if (pathname !== '/catalog') router.push(`/catalog?category=${child.id}`);
      },
    })),
  }));

  const onCatalogLinkClick = (ev) => {
    if (pathname.includes('/catalog')) {
      ev.preventDefault();
      clearFilters();
    }
  };

  const { superAdminJWT } = parseCookies();
  const isSuperAdminUndercover = Boolean(superAdminJWT);
  const role = userDetails?.me?.role || { name: '', absoluteSlug: '', slug: '', permissions: [] };
  const isProvider = role.absoluteSlug.includes('provider');
  const isSuperAdmin = role.slug === 'super-admin';
  const features = userDetails?.me?.client?.f || [];
  const clientId = userDetails?.me?.client?.clientId || '';
  const clients = dataClients?.clientsOfUser || [];
  const { providerType } = useProviderType({ skip: !isProvider });
  const userId = userDetails?.me?.id || '';
  const salesPoints = userDetails?.me?.salesPoints || [];
  const stripePayment = userDetails?.me?.providerInfo?.stripePayment || false;
  const providerSlug = userDetails?.me?.providerInfo?.slug;
  let selectedSalesPointId = userDetails?.me?.selectedSalesPointId || {};
  // salesPoints contain only enabled store linked to user (its filled only for local)
  if (role.absoluteSlug === 'local' && !salesPoints.some((sp) => sp.id === selectedSalesPointId)) {
    selectedSalesPointId = null;
  }

  const handleClick = () => {
    if (clients.length > 1) {
      setStoreSelectModalVisibility(!storeSelectModalVisibility);
    } else if (!selectedSalesPointId && salesPoints.length === 0) {
      router.push('/store/null');
    } else if (
      !role.permissions.includes('hyp.salespoints.list') &&
      salesPoints.length === 1 &&
      salesPoints[0].id === selectedSalesPointId
    ) {
      router.push(`/store/${selectedSalesPointId}`);
    } else {
      setStoreSelectModalVisibility(!storeSelectModalVisibility);
    }
  };

  // manage desktop side nav children accordions
  const [desktopAccordions, setDektopAccordions] = useState({});

  // make sure that the current desktop side accordion is open if active
  useEffect(() => {
    if (currentRoute.children) {
      // eslint-disable-next-line array-callback-return
      currentRoute.children.map((navChildren) => {
        if (asPath.includes(navChildren.path)) {
          setDektopAccordions({ ...desktopAccordions, [navChildren.i18n_value]: true });
        }
      });
    }
  }, []);

  const { data, loading } = useQuery(cart);
  const cartTotalQty = data?.cart?.items.map((i) => i.quantity).reduce((acc, qty) => acc + qty, 0);

  const isElementDisplayed = ({ navEl, actionEl }) => {
    const route = navEl || actionEl;
    if (isSuperAdmin && !route.userRole?.includes('super-admin')) return false;
    if (isProvider && !route.userRole?.some((_role) => _role === role.absoluteSlug)) {
      return false;
    }

    if (route.getOnClickPath) return route.getOnClickPath(role.permissions, features);
    if (route.children) {
      return (
        isElementDisplayed({ navEl: { ...route, children: undefined } }) &&
        route.children.some((child) => isElementDisplayed({ navEl: child }))
      );
    }

    if (!checkFeatureForNavigation(route, features)) return false;
    if (route.providerType && !providerType[route.providerType]) return false;
    if (route.userRole && !route.userRole.some((p) => p.includes(role.absoluteSlug))) return false;
    if (navEl && root !== '/provider' && route.i18n_value === 'provider') return false;
    if (route.permission && !role.permissions.includes(route.permission)) return false;
    if (
      route.permissionsOR &&
      !role.permissions.some((perm) => route.permissionsOR.includes(perm))
    ) {
      return false;
    }

    return true;
  };

  const navigateTo = (route) => {
    let { path } = route;
    if (route.getOnClickPath) {
      path = route.getOnClickPath(role.permissions, features);
      if (!path) return;
    }

    router.push(path);
  };

  /** @namespace isOneVision @private onevision */
  const isOneVision = true;

  const [isNewsModalVisible, setIsNewsModalVisible] = useState(true);

  const displayNavigation = (navElement, paths, path) => {
    if (navElement.feature === 'shop') {
      return (
        <UI.NavItem
          $active={paths.some((p) => p === root)}
          $hover
          $isText
          $isOneVision={isOneVision}
          onClick={
            !clientCategoriesShopOptions.enabled ||
            clientCategoriesShopOptions.showAllProductsLinkEnabled
              ? onCatalogLinkClick
              : (ev) => ev.preventDefault()
          }
          href="/catalog"
        >
          {navElement.customName || t([`nav.${navElement.i18n_value}`])}
        </UI.NavItem>
      );
    }

    if (navElement.newTab) {
      return (
        <a href={path} key={`mt_d_${navElement.i18n_value}`} target="_blank" rel="noreferrer">
          <UI.NavItem
            $active={paths.some((p) => p === root)}
            $hover
            $isText
            $isOneVision={isOneVision}
          >
            {navElement.customName || t([`nav.${navElement.i18n_value}`])}
          </UI.NavItem>
        </a>
      );
    }

    return (
      <Link href={path} key={`mt_d_${navElement.i18n_value}`} passHref>
        <UI.NavItem
          $active={paths.some((p) => p === root)}
          $hover
          $isText
          $isOneVision={isOneVision}
        >
          {navElement.customName || t([`nav.${navElement.i18n_value}`])}
        </UI.NavItem>
      </Link>
    );
  };

  return (
    <>
      <UI.Wrapper
        className={
          // if dev mode, display the Tailwind current breakpoint tooltip on the lower left corner
          process.env.NODE_ENV === 'development' ? 'debug-screens' : ''
        }
      >
        {openMultiOrga ? (
          <ModalMultiOrga
            openModal={openMultiOrga}
            setOpenModal={(open) => {
              if (!open) setStoreSelectModalVisibility(false);
              setOpenMultiOrga(open);
            }}
            onClose={() => setOpenMultiOrga(false)}
            hideCurrentOrga
          />
        ) : (
          <Modal
            visible={storeSelectModalVisibility}
            close={() => setStoreSelectModalVisibility(false)}
            outerClassName="close__modal !pt-0 !h-screen !overflow-y-hidden !grid place-items-center content-center"
            closeIconInModal
          >
            <UserStores
              onClose={() => setStoreSelectModalVisibility(false)}
              roleName={role.name}
              userId={userId}
              setOpenMultiOrga={setOpenMultiOrga}
            />
          </Modal>
        )}

        <UI.Navigation
          className={clsx(
            router.pathname === '/briefs/new-briefs' ? 'hidden' : '',
            isSuperAdmin ? 'sr-only' : '',
            'relative',
          )}
        >
          {/* logo and burger */}
          <UI.NavItemsGroup>
            <UI.NavItem $responsive onClick={() => setsideNavVisibility(!sideNavVisibility)}>
              <BsList className="text-xl" />
            </UI.NavItem>

            <Link href="/" passHref>
              <UI.NavItem $hover={false} className="h-full">
                <Logo providerSlug={providerSlug}>
                  {clients.length > 1 && (
                    <UI.NavItemsGroup $hideOnMd>
                      <UI.NavItem
                        $hover={false}
                        title={t(`cart:user-organisations.change-organisation`)}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenMultiOrga(true);
                        }}
                      >
                        <BsArrowLeftRight />
                      </UI.NavItem>
                    </UI.NavItemsGroup>
                  )}
                </Logo>
              </UI.NavItem>
            </Link>
          </UI.NavItemsGroup>

          {/* main top navigation, desktop only */}

          <UI.NavItemsGroup $hideOnMd>
            {navigation.map((navEl) => {
              if (!isElementDisplayed({ navEl })) return null;
              const paths = [navEl.path, ...navEl.active_paths];
              const path = navEl.getOnClickPath
                ? navEl.getOnClickPath(role.permissions, features)
                : navEl.path;

              return (
                <MegaMenu
                  key={navEl.i18n_value}
                  parentNavEl={navEl}
                  renderButton={(navElement) => displayNavigation(navElement, paths, path)}
                  secondaryNavigation={
                    navEl.feature === 'shop' && clientCategoriesShopOptions.enabled
                      ? clientProductMenuCategories
                      : []
                  }
                  tertiaryNavigation={[
                    navEl.feature === 'shop' &&
                      clientCategoriesShopOptions.enabled &&
                      clientCategoriesShopOptions.showAllProductsLinkEnabled && {
                        title: t('actions.show-all-products'),
                        path: '/catalog',
                        onClick: onCatalogLinkClick,
                      },
                  ].filter(Boolean)}
                />
              );
            })}

            {/* ClientId is needed to redirect properly so we cannot use the traditional navigation.js way */}
            {isOneVision && userDetails && (
              <a target="_blank" rel="noopener noreferrer" href={ovChartsUrl[clientId] || '#'}>
                <UI.NavItem $hover $isText $isOneVision={isOneVision}>
                  {t('nav.charts')}
                </UI.NavItem>
              </a>
            )}
          </UI.NavItemsGroup>

          {/* action icons section */}
          {root !== '/provider' && !isSuperAdmin && (
            <UI.NavItemsGroup>
              <UI.NavItem $hover={false} title={t([`actions.store_select`])} onClick={handleClick}>
                <BsShopWindow className="text-xl" />
              </UI.NavItem>
              {actions.map((actionEl) => {
                if (!isElementDisplayed({ actionEl })) return null;

                if (actionEl.i18n_value === 'user_profile') {
                  return (
                    <div
                      key={`action_${actionEl.i18n_value}`}
                      role="button"
                      tabIndex="0"
                      className={clsx(actionEl.hide_on_xs ? 'hidden lg:flex' : '')}
                    >
                      <UI.NavItem
                        $hover={false}
                        $hideOnXs={actionEl.hide_on_xs}
                        title={t(
                          `actions.${actionEl.i18n_value}`,
                          actionEl.i18n_value === 'user_profile' &&
                            isSuperAdminUndercover && { clientId: ` ${clientId}` },
                        )}
                      >
                        <Dropdown>
                          <DropdownMenuTrigger>
                            <BsPerson className="text-xl" />
                          </DropdownMenuTrigger>
                          <DropdownMenuContent className="w-56">
                            <DropdownMenuItem
                              onClick={() => router.push(`/my-account/personal-informations`)}
                            >
                              <BsPerson className="mr-2 h-4 w-4" />
                              <Text as="span">{t('nav.user_profile')}</Text>
                            </DropdownMenuItem>
                            {features.includes('shop') &&
                              role?.permissions?.includes('hyp.shop.list') && (
                                <DropdownMenuItem onClick={() => router.push(`/my-account/orders`)}>
                                  <ShoppingCartIcon className="mr-2 h-4 w-4" />
                                  <Text as="span">{t('nav.user_orders')}</Text>
                                </DropdownMenuItem>
                              )}
                            {isOneVision && features.includes('shop') && (
                              <DropdownMenuItem onClick={() => router.push(`/my-account/bills`)}>
                                <RiBillLine className="mr-2 h-4 w-4" />
                                <Text as="span">{t('nav.user_profile_bill')}</Text>
                              </DropdownMenuItem>
                            )}
                            <DropdownMenuItem onClick={signout}>
                              <MdLogout className="mr-2 h-4 w-4" />
                              <Text as="span">{t('actions.logout')}</Text>
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </Dropdown>
                      </UI.NavItem>
                    </div>
                  );
                }

                return (
                  <div
                    onClick={() => navigateTo(actionEl)}
                    role="button"
                    tabIndex="0"
                    onKeyDown={() => navigateTo(actionEl)}
                    key={`action_${actionEl.i18n_value}`}
                    className={clsx(
                      actionEl.hide_on_xs ? 'hidden lg:flex' : '',
                      actionEl.i18n_value === 'cart' && 'relative',
                    )}
                  >
                    <UI.NavItem
                      $hover={false}
                      $hideOnXs={actionEl.hide_on_xs}
                      title={t(
                        `actions.${actionEl.i18n_value}`,
                        actionEl.i18n_value === 'user_profile' &&
                          isSuperAdminUndercover && { clientId: ` ${clientId}` },
                      )}
                    >
                      {actionEl.icon}
                      {actionEl.i18n_value === 'cart' && !loading && cartTotalQty > 0 && (
                        <UI.Badge>{cartTotalQty > 9 ? '9+' : cartTotalQty}</UI.Badge>
                      )}
                    </UI.NavItem>
                  </div>
                );
              })}
              {features.includes('notifications') && !isSuperAdmin && (
                <UI.NavItem $hover={false} title={t([`actions.notifications`])}>
                  <Notifications t={t} />
                </UI.NavItem>
              )}
            </UI.NavItemsGroup>
          )}
        </UI.Navigation>

        {/* below top nav bar */}
        <UI.Content>
          {/* side navigation, mobile only */}
          {root !== 'provider' && (
            <UI.MobileSideNavigation $open={sideNavVisibility}>
              <UI.SideNavLevelHolder>
                <motion.div
                  animate={{ translateX: `${navOffset}rem` }}
                  transition={{ duration: 0.2, ease: 'easeOut' }}
                >
                  <UI.SideNavLevelSlider>
                    <UI.SwipingNavItem>
                      {navigation.map((navEl) => {
                        if (!isElementDisplayed({ navEl })) return null;

                        const paths = navEl.active_paths
                          ? [navEl.path, ...navEl.active_paths]
                          : [navEl.path];

                        return (
                          <UI.SideNavItem
                            $active={paths.some((p) => p.includes(root))}
                            onClick={() => {
                              if (navEl.children) {
                                setL2({
                                  parent: t([`nav.${navEl.i18n_value}`]),
                                  children: navEl.children,
                                });
                              } else {
                                router.push(navEl.path);
                              }
                            }}
                            key={`sn_m_${navEl.i18n_value}`}
                          >
                            {navEl.customName || t([`nav.${navEl.i18n_value}`])}
                            {navEl.children && <BsChevronRight className="text-xl" />}
                          </UI.SideNavItem>
                        );
                      })}

                      {isOneVision && userDetails && (
                        <Link href={ovChartsUrl[clientId] || '#'} passHref>
                          <UI.SideNavItem $hover $isText $isOneVision={isOneVision}>
                            {t('nav.charts')}
                          </UI.SideNavItem>
                        </Link>
                      )}

                      {features.includes('shop') &&
                        role?.permissions?.includes('hyp.shop.list') && (
                          <UI.SideNavItem
                            onClick={() => router.push(`/my-account/orders`)}
                            key="sn_m_user_orders"
                          >
                            {t([`nav.user_orders`])}
                          </UI.SideNavItem>
                        )}

                      {isOneVision && features.includes('shop') && (
                        <UI.SideNavItem
                          onClick={() => router.push(`/my-account/bills`)}
                          key="sn_m_user_profile_bill"
                        >
                          {t([`nav.user_profile_bill`])}
                        </UI.SideNavItem>
                      )}

                      {actions.map((actionEl) => {
                        if (!isElementDisplayed({ actionEl })) return null;

                        const paths = actionEl.active_paths
                          ? [actionEl.path, ...actionEl.active_paths]
                          : [actionEl.path];

                        return (
                          <UI.SideNavItem
                            $active={paths.some((p) => p === root)}
                            $hover={false}
                            $hideOnXs={!actionEl.hide_on_xs}
                            title={t([`actions.${actionEl.i18n_value}`])}
                            onClick={() => {
                              if (actionEl.children) {
                                setL2({
                                  parent: t([`actions.${actionEl.i18n_value}`]),
                                  children: actionEl.children,
                                });
                              } else {
                                router.push(actionEl.path);
                              }
                            }}
                            key={`sn_m_${actionEl.i18n_value}`}
                          >
                            {t([`actions.${actionEl.i18n_value}`])}
                            {actionEl.children && <BsChevronRight className="text-xl" />}
                          </UI.SideNavItem>
                        );
                      })}
                    </UI.SwipingNavItem>
                    <UI.SwipingNavItem>
                      {!l2 ? (
                        <UI.SideNavItem />
                      ) : (
                        <>
                          <UI.SideNavItem
                            className="!justify-start font-medium"
                            onClick={() => {
                              setnavOffset(navOffset + 16);
                            }}
                          >
                            <BsChevronLeft className="text-primary-500" />
                            {l2.parent}
                          </UI.SideNavItem>
                          {l2.children.map((navEl) => {
                            if (!isElementDisplayed({ navEl })) return null;

                            const paths = navEl.active_paths
                              ? [navEl.path, ...navEl.active_paths]
                              : [navEl.path];

                            if (navEl.children) {
                              paths.push(
                                ...navEl.children
                                  .map((child) =>
                                    child.active_paths
                                      ? [child.path, ...child.active_paths]
                                      : [child.path],
                                  )
                                  .flat(),
                              );
                            }

                            return (
                              <UI.SideNavItem
                                $active={paths.some((p) => asPath?.startsWith(p))}
                                onClick={() => {
                                  if (navEl.children) {
                                    setL3({
                                      parent: t([`nav.${navEl.i18n_value}`]),
                                      children: navEl.children,
                                    });
                                  } else {
                                    router.push(navEl.path);
                                  }
                                }}
                                key={`mb_sn_l2_${navEl.i18n_value}`}
                              >
                                {navEl.customName || t([`nav.${navEl.i18n_value}`])}
                                {navEl.children && <BsChevronRight className="text-xl" />}
                              </UI.SideNavItem>
                            );
                          })}
                        </>
                      )}
                    </UI.SwipingNavItem>
                    <UI.SwipingNavItem>
                      {!l3 ? (
                        <UI.SideNavItem />
                      ) : (
                        <>
                          <UI.SideNavItem
                            className="!justify-start font-medium"
                            onClick={() => {
                              setnavOffset(navOffset + 16);
                            }}
                          >
                            <BsChevronLeft className="text-primary-500" />
                            {t('actions.back')}
                          </UI.SideNavItem>
                          {l3.children.map((navEl) => {
                            if (!isElementDisplayed({ navEl })) return null;
                            const isActive = isChildrenActive(asPath, navEl, l3);

                            return (
                              <UI.SideNavItem
                                $active={isActive}
                                onClick={() => {
                                  router.push(navEl.path);
                                }}
                                key={`mb_sn_l3_${navEl.i18n_value}`}
                              >
                                {navEl.customName || t([`nav.${navEl.i18n_value}`])}
                              </UI.SideNavItem>
                            );
                          })}
                        </>
                      )}
                    </UI.SwipingNavItem>
                  </UI.SideNavLevelSlider>
                </motion.div>
              </UI.SideNavLevelHolder>

              <UI.MobileLogout onClick={signout}>{t('actions.logout')}</UI.MobileLogout>
            </UI.MobileSideNavigation>
          )}
          {/* side navigation, desktop only todo */}
          {currentRoute?.children && (
            <UI.SideNavigation>
              <div className="w-full pb-36">
                {currentRoute.children.map((navEl) => {
                  if (!isElementDisplayed({ navEl })) return null;

                  return (
                    <motion.div className="mb-2 flex flex-col" key={`dk_${navEl.i18n_value}`}>
                      <motion.div
                        onClick={() => {
                          if (navEl.children) {
                            setDektopAccordions({
                              ...desktopAccordions,
                              [navEl.i18n_value]: !desktopAccordions[navEl.i18n_value],
                            });
                          }

                          if (!navEl.children && navEl.path) router.push(navEl.path);
                        }}
                      >
                        <UI.SideNavItem
                          $active={asPath.includes(navEl.path)}
                          className="flex items-center justify-between"
                        >
                          {navEl.customName || t([`nav.${navEl.i18n_value}`])}
                          {navEl.children && (
                            <motion.div
                              animate={{ rotate: desktopAccordions[navEl.i18n_value] ? 180 : 360 }}
                            >
                              <BsChevronDown />
                            </motion.div>
                          )}
                        </UI.SideNavItem>
                      </motion.div>
                      <AnimatePresence>
                        {navEl.children && desktopAccordions[navEl.i18n_value] && (
                          <motion.div
                            initial="collapsed"
                            animate="open"
                            exit="collapsed"
                            variants={{
                              open: { opacity: 1, height: 'auto' },
                              collapsed: { opacity: 0, height: 0 },
                            }}
                            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                            className="overflow-hidden"
                          >
                            {navEl.children.map((subNavEl) => {
                              if (!isElementDisplayed({ navEl: subNavEl })) return null;
                              const isActive = isChildrenActive(asPath, subNavEl, navEl);

                              return (
                                <Link
                                  href={subNavEl.path}
                                  key={`dk_${subNavEl.i18n_value}`}
                                  passHref
                                >
                                  <UI.SideNavItem $active={isActive} $offset>
                                    {subNavEl.customName || t([`nav.${subNavEl.i18n_value}`])}
                                  </UI.SideNavItem>
                                </Link>
                              );
                            })}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </motion.div>
                  );
                })}
                {isProvider && stripePayment && (
                  <Link href="/provider/stripe-connect" passHref>
                    <UI.SideNavItem
                      $active={asPath.includes('/provider/stripe-connect')}
                      className="flex items-center gap-2 pr-4"
                    >
                      {t('actions.stripe-connect')}
                    </UI.SideNavItem>
                  </Link>
                )}
                {(root === '/provider' || root === '/super-admin') && (
                  <UI.SideNavItem onClick={signout} className="flex items-center gap-2 pr-4">
                    {t('actions.logout')}
                    <BsBoxArrowRight className="text-xl" />
                  </UI.SideNavItem>
                )}
              </div>
            </UI.SideNavigation>
          )}

          {/* show actual view contents */}
          <UI.ViewWrapper id="main" className={clsx(currentRoute?.children && 'lg:pl-72')}>
            {sideNavVisibility && (
              <UI.Overlay
                $active={sideNavVisibility}
                onClick={() => {
                  setsideNavVisibility(!sideNavVisibility);
                }}
              />
            )}
            <div className="flex h-full max-h-[calc(100vh-4.75rem)] min-h-full flex-col justify-between">
              {children}
            </div>
          </UI.ViewWrapper>
        </UI.Content>
        <Toaster
          position="top-right"
          toastOptions={{
            style: {
              alignItems: 'start',
            },
            success: {
              icon: <BsCheckCircle className="my-1.5 ml-1 text-lg text-green-500 lg:text-xl" />,
            },
            error: {
              icon: <BsXCircle className="my-1.5 ml-1 text-lg text-red-500 lg:text-xl" />,
            },
            loading: {
              icon: (
                <CgSpinner className="my-1.5 ml-1 animate-spin text-lg text-gray-700 lg:text-xl" />
              ),
            },
          }}
        />
        {root !== '/provider' && root !== '/super-admin' && (
          <NewsModal visible={isNewsModalVisible} close={() => setIsNewsModalVisible(false)} />
        )}
        <QaReportButton />
      </UI.Wrapper>
    </>
  );
};

Interface.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default flow(withTranslation('ui'))(Interface);
