import React, { ReactNode, useEffect } from 'react';
import { BriefsProvider } from 'screens/Briefs/contexts/briefsContext/briefsContext';
import { SocialProvider } from 'screens/Social/context/socialContext';

type AppProvidersProps = {
  children: ReactNode;
};

const AppProviders: React.FC<AppProvidersProps> = ({ children }) => {
  return (
    <BriefsProvider>
      <SocialProvider>
        {/* Autres providers ici. Choisir l'ordre pour avoir accès aux données des contexts supérieurs pour ceux inférieurs*/}
        {children}
      </SocialProvider>
    </BriefsProvider>
  );
};

export default AppProviders;
