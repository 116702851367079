/* eslint-disable indent */
import styled from 'styled-components';
import devices from 'tokens/devices';

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  .layout__header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
    background-color: white;
    box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.2);
  }

  .layout__main {
    height: 100%;
    opacity: ${({ isOpen }) => (!isOpen ? '1' : '0.2')};
  }

  .footer-cookie-active {
    margin-bottom: 124px;
  }
  @media ${devices.mobile}, ${devices.xsmall} {
    .layout__main {
      opacity: ${({ isOpen, isOpenMenu }) => (isOpen || isOpenMenu ? '0.2' : '1')};
    }
  }
`;

export default LayoutWrapper;
