import React, { createContext, ReactNode, useReducer, useContext } from 'react';
import AnswerTemplateReducer from './answerTemplateReducer';
import {AvailableLanguages} from '@schema';

export type AnswerTemplateStateType = {
  keyword: string;
  language: string;
  types: string[];
  ratings: string[];
  limitRowPerPage: number;
  offset: number;
  sort: { criteria: string; direction: 'asc' | 'desc' };
  currentPage: number;
};

export const defaultInitialAnswerTemplateState: AnswerTemplateStateType = {
  keyword: '',
  language: AvailableLanguages.All,
  types: [],
  ratings: [],
  limitRowPerPage: 10,
  offset: 0,
  sort: { criteria: 'createdAt', direction: 'desc' },
  currentPage: 0,
};

export type Action =
  | { type: 'SET_KEYWORD'; payload: string }
  | { type: 'SET_LANGUAGE'; payload: string }
  | { type: 'SET_TYPES'; payload: string[] }
  | { type: 'SET_RATINGS'; payload: string[] }
  | { type: 'SET_LIMIT_ROW_PER_PAGE'; payload: number }
  | { type: 'SET_OFFSET'; payload: number }
  | { type: 'SET_CURRENT_PAGE'; payload: number }
  | { type: 'SET_SORT'; payload: { criteria: string; direction: 'asc' | 'desc' } };

const AnswerTemplateStateContext = createContext<AnswerTemplateStateType | undefined>(undefined);
const AnswerTemplateDispatchContext = createContext<React.Dispatch<Action> | undefined>(undefined);

export function AnswerTemplateProvider({
  children,
  initialStateOverWritten = defaultInitialAnswerTemplateState,
}: {
  children: ReactNode;
  initialStateOverWritten?: AnswerTemplateStateType;
}) {
  const [state, dispatch] = useReducer(AnswerTemplateReducer, {
    ...defaultInitialAnswerTemplateState,
    ...initialStateOverWritten,
  });

  return (
    <AnswerTemplateStateContext.Provider value={state}>
      <AnswerTemplateDispatchContext.Provider value={dispatch}>
        {children}
      </AnswerTemplateDispatchContext.Provider>
    </AnswerTemplateStateContext.Provider>
  );
}

export function useAnswerTemplateState() {
  const context = useContext(AnswerTemplateStateContext);
  if (context === undefined) {
    throw new Error('useAnswerTemplateState must be used within a AnswerTemplateProvider');
  }
  return context;
}

export function useAnswerTemplateDispatch() {
  const context = useContext(AnswerTemplateDispatchContext);
  if (context === undefined) {
    throw new Error('useAnswerTemplateDispatch must be used within a AnswerTemplateProvider');
  }
  return context;
}
