import React from 'react';
import getConfig from 'next/config';
import { Button, Text } from 'flex';
import { parseCookies } from 'nookies';
import { BsLink, BsMegaphoneFill } from 'react-icons/bs';
import * as Sentry from '@sentry/nextjs';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { omit } from 'lodash';
import Toast from 'react-hot-toast';

const { publicRuntimeConfig } = getConfig();

const ME = gql`
  query reportInfo {
    me {
      id
      email
      role {
        id
        slug
        absoluteSlug
      }
      selectedSalesPoint {
        id
        name
      }
    }
  }
`;

const MUTATION = gql`
  mutation createJiraIssue($sentryEnv: String, $sentryEventId: String, $currentUrl: String) {
    createJiraIssue(sentryEnv: $sentryEnv, sentryEventId: $sentryEventId, currentUrl: $currentUrl)
  }
`;

const { sentryEnvironment, qaButtonEnabled } = publicRuntimeConfig;

export default function QaReportButton() {
  const client = useApolloClient();

  const [createJiraIssue] = useMutation(MUTATION);
  const cookies = parseCookies();
  const { superAdminJWT, client_id: clientId } = cookies;
  const enabled = qaButtonEnabled || superAdminJWT;

  if (!enabled) return null;

  const getMe = async () => {
    const {
      data: { me },
    } = await client.query({
      query: ME,
    });

    return me;
  };

  const copyLink = async () => {
    const { email } = await getMe();
    const { protocol, host, pathname } = window.location;
    navigator.clipboard.writeText(
      `${protocol}//${host}/autologin?email=${email}&clientId=${clientId}&redirectTo=${pathname}`,
    );
    Toast.success(<Text>Lien copié !</Text>);
  };

  const createReport = async () => {
    const me = await getMe();
    Sentry.setUser({
      id: me.id,
      email: me.email,
    });
    Sentry.setTag('selected_store', omit(me.selectedSalesPoint));
    Sentry.setTag('client_id', me.clientId);
    Sentry.setTag('role', omit(me.role, '__typename'));

    const { data } = await createJiraIssue({
      variables: {
        sentryEnv: sentryEnvironment,
        currentUrl: window.location.href,
      },
    });
    window.open(data.createJiraIssue, '_blank');

    return null;
  };

  return (
    <div className="fixed bottom-6 left-3 z-50 space-x-4 opacity-20 transition-all hover:opacity-100">
      <Button
        onClick={createReport}
        icon={<BsMegaphoneFill className="text-lg" />}
        iconPosition="only"
        destructive
      />
      <Button onClick={copyLink} icon={<BsLink className="text-lg" />} iconPosition="only" />
    </div>
  );
}
