import { BriefStatus } from '@schema';
import { InitialStateType, FiltersType, Action } from './briefsContext';

const DEFAULT_CURRENT_PAGE = 1;
const DEFAULT_PARAMS = { offset: 0, limit: 10 };

const briefsReducer = (state: InitialStateType, action: Action) => {
  switch (action.type) {
    case 'SET_TOTAL_BRIEFS_WITH_FILTERS':
      return {
        ...state,
        totalActivatedBriefsWithFilters: action.payload.totalActivatedBriefsWithFilters,
        totalArchivedBriefsWithFilters: action.payload.totalArchivedBriefsWithFilters,
      };

    case 'SET_TOTAL_BRIEFS_WITHOUT_FILTERS':
      return {
        ...state,
        totalActivatedBriefsWithoutFilters: action.payload.totalActivatedBriefsWithoutFilters,
        totalArchivedBriefsWithoutFilters: action.payload.totalArchivedBriefsWithoutFilters,
      };

    case 'SET_FILTER':
      return updateFiltersState(state, action.payload);

    case 'SET_SHOW_FILTERS':
      return {
        ...state,
        showFilters: !state.showFilters,
      };

    case 'SET_IS_ARCHIVED_DISPLAYED':
      if (state.isArchivedDisplayed) {
        return {
          ...state,
          isArchivedDisplayed: action.payload,
        };
      } else {
        return {
          ...state,
          isArchivedDisplayed: action.payload,
          filters: {
            ...state.filters,
            status: state.filters.status.filter(
              (status) => status === BriefStatus.Done || status === BriefStatus.Cancelled,
            ),
          },
        };
      }
    case 'SET_PAGE':
      if (state.isArchivedDisplayed) {
        return {
          ...state,
          currentPageArchived: action.payload,
          paramsArchived: {
            ...state.paramsArchived,
            offset: (action.payload - 1) * state.paramsArchived.limit,
          },
        };
      } else {
        return {
          ...state,
          currentPageActivated: action.payload,
          paramsActivated: {
            ...state.paramsActivated,
            offset: (action.payload - 1) * state.paramsActivated.limit,
          },
        };
      }
    case 'SET_ABANDON_MODAL':
      return {
        ...state,
        isAbandonModalOpen: { isOpen: action.payload.isOpen, briefId: action.payload.briefId },
      };

    case 'SET_ROLES':
      return {
        ...state,
        isProvider: action.payload.isProvider,
        canCreateAndUpdateBriefs: action.payload.canCreateAndUpdateBriefs,
      };

    case 'SET_LIMIT':
      if (state.isArchivedDisplayed) {
        return {
          ...state,
          paramsArchived: {
            ...state.paramsArchived,
            limit: action.payload,
          },
        };
      } else {
        return {
          ...state,
          paramsActivated: {
            ...state.paramsActivated,
            limit: action.payload,
          },
        };
      }

    case 'SET_NO_HEADER':
      return {
        ...state,
        noHeader: action.payload,
      };

    case 'SET_LAYOUT':
      return {
        ...state,
        asLayout: action.payload,
      };

    case 'SET_CAMPAIGN_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          campaigns: action.payload,
        },
      };

    case 'SET_DISPLAY_BRIEFS_IN_LIST_MODE':
      return {
        ...state,
        isDisplayingBriefsInListMode: action.payload,
      };

    case 'SET_SORT_FIELD':
      return {
        ...state,
        sortField: action.payload,
      };

    default:
      return state;
  }
};

export default briefsReducer;

function updateFiltersState(state: InitialStateType, changes: Partial<FiltersType>) {
  const isDefaultFilters = checkDefaultFiltersValues(
    changes?.keyword ?? state.filters.keyword,
    changes?.status ?? state.filters.status,
    changes?.contact ?? state.filters.contact,
    changes?.salesPointGroupIds ?? state.filters.salesPointGroupIds,
    changes?.salesPointIds ?? state.filters.salesPointIds,
    changes?.campaigns ?? state.filters.campaigns,
  );

  return {
    ...state,
    filters: {
      ...state.filters,
      ...changes,
    },
    isDefaultFilters: isDefaultFilters,
    currentPageActivated: DEFAULT_CURRENT_PAGE,
    currentPageArchived: DEFAULT_CURRENT_PAGE,
    paramsActivated: DEFAULT_PARAMS,
    paramsArchived: DEFAULT_PARAMS,
  };
}

function checkDefaultFiltersValues(
  keyword: string,
  status: BriefStatus[],
  contact: string | null,
  salesPointGroupIds: string[],
  salesPointIds: string[],
  campaignId: string | null,
) {
  return (
    keyword === '' &&
    status.length === 0 &&
    contact === null &&
    salesPointGroupIds.length === 0 &&
    salesPointIds.length === 0 &&
    campaignId === null
  );
}
