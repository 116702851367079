import { AnswerTemplateStateType, Action } from './answerTemplateContext';

const AnswerTemplateReducer = (state: AnswerTemplateStateType, action: Action) => {
  switch (action.type) {
    case 'SET_KEYWORD':
      return { ...state, keyword: action.payload };

    case 'SET_LANGUAGE':
      return { ...state, language: action.payload };

    case 'SET_TYPES':
      return { ...state, types: action.payload };

    case 'SET_RATINGS':
      return { ...state, ratings: action.payload };

    case 'SET_LIMIT_ROW_PER_PAGE':
      return { ...state, limit: action.payload };

    case 'SET_OFFSET':
      return { ...state, offset: action.payload };

    case 'SET_CURRENT_PAGE':
      return { ...state, currentPage: action.payload };

    case 'SET_SORT':
      return { ...state, sort: action.payload };

    default:
        throw new Error(`Unhandled action type: ${(action as Action).type}`);
  }
};

export default AnswerTemplateReducer;
