/* eslint-disable @typescript-eslint/no-var-requires */
const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './flex/**/*.{js,ts,jsx,tsx}',
    './screens/**/*.{js,ts,jsx,tsx}',
  ],
  safelist: [
    'text-facebook',
    'text-twitter',
    'text-pinterest',
    'text-youtube',
    'text-linkedin',
    'text-vimeo',
    'text-xing',
    'text-foursquare',
    'text-google',
    'text-instagram',
  ],
  theme: {
    extend: {
      fontFamily: {
        primary: ['var(--primary-font)', 'Rubik', ...defaultTheme.fontFamily.sans],
        secondary: ['var(--secondary-font)', 'Poppins', ...defaultTheme.fontFamily.sans],
        title: `var(--font-title, 'Lexend', ${defaultTheme.fontFamily.sans.join(', ')})`,
        body: `var(--font-body, 'Inter', ${defaultTheme.fontFamily.sans.join(', ')})`,
        rubik: ['Rubik', 'sans-serif'],
        poppins: ['Poppins', 'sans-serif'],
        roboto: ['Roboto', 'Arial', 'sans-serif'],
      },
      minHeight: (theme) => ({
        ...theme('spacing'),
      }),
      minWidth: (theme) => ({
        ...theme('spacing'),
      }),
      maxWidth: {
        max: 'max-content',
        min: 'min-content',
      },
      transitionProperty: {
        width: 'width',
      },
      lineClamp: {
        7: '7',
        8: '8',
        9: '9',
        10: '10',
      },
      colors: {
        brand: {
          50: 'var(--brand-50, #ecf1fe)',
          100: 'var(--brand-100, #c2d3fe)',
          200: 'var(--brand-200, #BDD8FF)',
          300: 'var(--brand-300, #7AB1FF)',
          400: 'var(--brand-400, #3B84ED)',
          500: 'var(--brand-500, #004EBF)',
          600: 'var(--brand-600, #0742A0)',
          700: 'var(--brand-700, #0741cb)',
          800: 'var(--brand-800, #073dbf)',
          900: 'var(--brand-900, #052c8a)',
        },
        primary: {
          50: 'var(--primary-50)',
          100: 'var(--primary-100)',
          200: 'var(--primary-200)',
          300: 'var(--primary-300)',
          400: 'var(--primary-400)',
          500: 'var(--primary-500)',
          600: 'var(--primary-600)',
          700: 'var(--primary-700)',
          800: 'var(--primary-800)',
          900: 'var(--primary-900)',
        },
        gray: {
          100: '#FBFBFB',
          200: '#F7F7F7',
          300: '#F1F0F0',
          400: '#E6E6E6',
          500: '#CDCDCD',
          600: '#9D9D9D',
          700: '#707070',
          800: '#464646',
          900: '#202020',
          light: '#999694',
        },
        success: {
          100: '#E3FFEF',
          200: '#BCF8D6',
          300: '#81ECAF',
          400: '#37C173',
          500: '#0EBA59',
          600: '#009538',
          700: '#007216',
          800: '#005000',
          900: '#003100',
        },
        info: {
          100: '#F2F8FF',
          200: '#C7E2FF',
          300: '#94CBFF',
          400: '#42AAFF',
          500: '#0095FF',
          600: '#006FD6',
          700: '#0057C2',
          800: '#0041A8',
          900: '#002885',
        },
        warning: {
          100: '#FFFBF7',
          200: '#FFE7D6',
          300: '#FFCDA9',
          400: '#FFA969',
          500: '#FB7A1A',
          600: '#D15800',
          700: '#A83500',
          800: '#810D00',
          900: '#5E0000',
        },
        danger: {
          100: '#FFF1F0',
          200: '#FEE1DF',
          300: '#FFB4AF',
          400: '#FF6B61',
          500: '#F44336',
          600: '#D0191D',
          700: '#AC0002',
          800: '#890000',
          900: '#690000',
        },
        text: {
          100: '#D1D2E2',
          200: '#A7A7B7',
          300: '#7E7F8E',
          400: '#585967',
          500: '#343542',
          600: '#272835',
          700: '#1F202B',
          800: '#191A25',
          900: '#161620',
        },
        yellow: {
          100: '#FDF7E9',
          200: '#F9E7BF',
          300: '#F5D895',
          400: '#F1C86B',
          500: '#EDB941',
          600: '#E1A315',
          700: '#AD7E10',
          800: '#7A590C',
          900: '#473307',
        },
        tuna: {
          50: '#F2F5F8',
          100: '#D4D5DD',
          200: '#A7A8B9',
          300: '#7C7E98',
          400: '#55576C',
          500: '#343542',
          600: '#282933',
          700: '#1F2028',
          800: '#14151A',
          900: '#0B0B0E',
        },
        radio: '#0f7070',
        presse: '#56b6af',
        video: '#3366ff',
        facebook: '#0095FF',
        twitter: '#0084B4',
        pinterest: '#C8232C',
        youtube: '#FF0000',
        linkedin: '#0A66C2',
        vimeo: '#1A2E3B',
        xing: '#006567',
        foursquare: '#E65778',
        instagram: '#E1306C',
        google: '#DD4B39',
        bronze: '#7C6750',
        download: '#FFE180',
        transparent: 'transparent',
      },
      screens: {
        '3xl': '1920px',
      },
      width: {
        6.5: '1.625rem',
        7.5: '1.875rem',
        8.5: '2.125rem',
        9.5: '2.375rem',
        '90p': '90%', // largeur 90%
        '95p': '95%', // largeur 95%
      },
      height: {
        6.5: '1.625rem',
        7.5: '1.875rem',
        8.5: '2.125rem',
        9.5: '2.375rem',
        '90p': '90%', // hauteur 90%
        '95p': '95%', // hauteur 95%
      },
      translate: {
        6.5: '1.625rem',
        7.5: '1.875rem',
        8.5: '2.125rem',
        9.5: '2.375rem',
      },
      spacing : {
        'O.25': '0.0625rem',
       }
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    // eslint-disable-next-line import/no-extraneous-dependencies
    require('tailwindcss-debug-screens'),
    require('@tailwindcss/forms')({
      strategy: 'class', // only generate classes
    }),
    // eslint-disable-next-line import/no-extraneous-dependencies
    require('@tailwindcss/typography'),
    require('@tailwindcss/container-queries'),
  ],
};
