import PropTypes from 'prop-types';

export default PropTypes.arrayOf(
  PropTypes.shape({
    definition: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
      textConfig: PropTypes.shape({
        paragraph: PropTypes.bool.isRequired,
        dynVar: PropTypes.bool.isRequired,
      }),
      numberConfig: PropTypes.shape({
        precision: PropTypes.number.isRequired,
      }),
      choiceConfig: PropTypes.shape({
        displayType: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      }),
      multipleChoiceConfig: PropTypes.shape({
        displayType: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      }),
      dateConfig: PropTypes.shape({
        isDateTime: PropTypes.bool.isRequired,
        timezone: PropTypes.string.isRequired,
      }),
    }).isRequired,
    textValue: PropTypes.string,
    numberValue: PropTypes.number,
    choiceValue: PropTypes.string,
    multipleChoiceValue: PropTypes.arrayOf(PropTypes.string.isRequired),
    dateValue: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }),
);
