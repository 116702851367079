import { useQuery } from '@apollo/client';
import PROVIDER_TYPE from 'graphql/queries/providerType.graphql';
import { useMemo } from 'react';

export default function useProviderType({ skip = false } = {}) {
  const { data, loading } = useQuery(PROVIDER_TYPE, { skip });
  const providerType = useMemo(
    () =>
      ['mkp', 'brief'].reduce(
        (out, type) => ({
          ...out,
          [type]:
            data?.me.providerInfo?.linkedClients.some((client) => client.providerType === type) ||
            false,
        }),
        {},
      ),
    [data],
  );

  return { loading, providerType };
}
