import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import * as Sentry from '@sentry/nextjs';
import { get } from 'lodash';
import { useRouter } from 'next/router';
import Loader from 'components/Loader/Loader';
import { useQuery } from '@apollo/react-hooks';
import useSentryContext from 'components/hooks/useSentryContext';
import { parseCookies } from 'nookies';
import QUERY_ME from 'graphql/queries/getBasicUserInfo.graphql';
import Meta from 'components/Meta';
import UI from 'components/UI';
import ConsentBanner from 'components/ConsentBanner';
import useClarityScript from 'components/hooks/useClarityScript';
import useWidgetScript from 'components/hooks/useWidgetScript';
import { withSearchContext } from 'components/hooks/FiltersContext';
import { useCreateEventMutation } from '@schema';
import LayoutWrapper from './LayoutWrapper';
import AppProviders from 'components/AppProviders/AppProviders';

const { publicRuntimeConfig } = getConfig();
const { zendeskWidgetUrl, zendeskWidgetId, environment, clientName } = publicRuntimeConfig;

function Layout({ children }) {
  const router = useRouter();
  const { data, loading } = useQuery(QUERY_ME, { fetchPolicy: 'network-only' });
  const isAuthenticated = Boolean(get(data, 'me.email', ''));
  const role = get(data, 'me.role.name', '');
  const userId = get(data, 'me.id', '');
  const email = get(data, 'me.email', '');
  const { client_id: clientId } = parseCookies();
  const [createEvent] = useCreateEventMutation();

  useWidgetScript(zendeskWidgetUrl, zendeskWidgetId, data?.me?.languagePreference);
  useClarityScript(clientName, environment);
  useSentryContext(userId, clientId, role, email);

  useEffect(() => {
    if (!isAuthenticated && !loading) {
      router.push('/');
    }
  }, [isAuthenticated, loading]);

  useEffect(() => {
    const { pathname, query } = router;

    if (['/marketing/[id]', '/campaigns/[id]'].includes(pathname)) {
      const { id } = query;

      createEvent({
        variables: {
          type: 'PAGE_VIEW',
          subType: 'CAMPAIGN',
          extra: JSON.stringify({ campaignId: id }),
        },
      }).catch((e) => {
        Sentry.captureException(e);
      });
    }
  }, [router.pathname, router.query]);

  return (
    <AppProviders>
      <LayoutWrapper id="layout-wrapper">
        <Meta />
        <UI>
          <main className="layout__main">
            {isAuthenticated && !loading ? (
              children
            ) : (
              <Loader type="Puff" loading style={{ height: 'calc(100vh - 238px)' }} />
            )}
          </main>
        </UI>
        <ConsentBanner />
      </LayoutWrapper>
    </AppProviders>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withSearchContext(Layout);
