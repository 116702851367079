import React from 'react';

// librairies
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import { useRouter } from 'next/router';
import { BsArrowRight, BsCart, BsNewspaper, BsChatSquareText, BsCheckCircle } from 'react-icons/bs';

// global components
import Text from 'components/Text/Text';
import Title from 'components/Title/Title';

const icons = {
  order: <BsCart className="text-xl" />,
  publish: <BsNewspaper className="text-xl" />,
  converse_comment: <BsChatSquareText className="text-xl" />,
};

const routes = {
  order: '/my-account/orders?tab=validated',
  converse_comment: '/social/messaging?status=to-moderate',
  publish: '/social/publications?status=moderation',
  amplify: '/social/amplify',
};

const Validations = ({ t, validationItems, validationTotal, loadingValidations }) => {
  const router = useRouter();
  const notificationNames = Object.keys(validationItems);

  if (loadingValidations) {
    return (
      <div className="flex w-full flex-col gap-2">
        <div className="w-8/12 bg-gray-300 animate-pulse h-4 rounded" />
        <div className="w-8/12 bg-gray-300 animate-pulse h-4 rounded" />
        <div className="w-8/12 bg-gray-300 animate-pulse h-4 rounded" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-y-4">
      {validationTotal > 0 ? (
        notificationNames.map((name) => {
          const notificationCount = validationItems[name].count;

          return (
            notificationCount > 0 && (
              <button
                key={name}
                type="button"
                onClick={() => {
                  router.push(routes[name]);
                }}
                className="flex gap-2 text-gray-800 hover:text-primary-500"
              >
                {icons[name]}
                <Text className="transition hover:text-primary-500 flex gap-1 items-center group">
                  {t(`notifications.${name}`, { count: notificationCount })}
                  <BsArrowRight className="stroke-1 transform transition-all -translate-x-1 opacity-0 group-hover:translate-x-0 group-hover:opacity-100 text-xl" />
                </Text>
              </button>
            )
          );
        })
      ) : (
        <div className="flex border-solid border-2 border-gray-300 rounded-lg p-11 justify-center text-gray-800">
          <div className="flex flex-col content-center justify-center gap-4">
            <BsCheckCircle className="w-14 h-14 self-center " />
            <div className="flex flex-col">
              <Title size="h4" className="self-center">
                {t('notifications.no-data')}
              </Title>
              <Text size="p1" className="self-center">
                {t('notifications.no-data-description')}
              </Text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Validations.propTypes = {
  t: PropTypes.func.isRequired,
  loadingValidations: PropTypes.bool.isRequired,
  validationTotal: PropTypes.number.isRequired,
  validationItems: PropTypes.shape({
    amplify: PropTypes.shape({
      enabled: PropTypes.bool,
      count: PropTypes.number,
    }),
    converse_comment: PropTypes.shape({
      enabled: PropTypes.bool,
      count: PropTypes.number,
    }),
    order: PropTypes.shape({
      enabled: PropTypes.bool,
      count: PropTypes.number,
    }),
    publish: PropTypes.shape({
      enabled: PropTypes.bool,
      count: PropTypes.number,
    }),
  }).isRequired,
};

export default withTranslation('ui')(Validations);
