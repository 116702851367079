import React from 'react';

import PropTypes from 'prop-types';
import { CompositeDecorator, EditorState, Modifier } from 'draft-js';

const Link = ({ entityKey, contentState, children }) => {
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <a className="text-info-500 underline" href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

Link.propTypes = {
  entityKey: PropTypes.string.isRequired,
  contentState: PropTypes.shape({
    getEntity: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();

    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};

export const createLinkDecorator = () =>
  new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

const addEntityToState = ({ editorState, url, linkText, decorator }) => {
  const currentContent = editorState.getCurrentContent();
  currentContent.createEntity('LINK', 'MUTABLE', {
    url,
  });

  const entityKey = currentContent.getLastCreatedEntityKey();
  const selection = editorState.getSelection();

  if (selection.isCollapsed()) {
    const textWithEntity = Modifier.insertText(
      currentContent,
      selection,
      linkText || url,
      null,
      entityKey,
    );

    return EditorState.createWithContent(textWithEntity, decorator);
  }

  const textWithEntity = Modifier.replaceText(currentContent, selection, linkText, null, entityKey);

  return EditorState.createWithContent(textWithEntity, decorator);
};

export const onAddLink = ({ editorState, setEditorState, url, linkText }) => {
  const decorator = createLinkDecorator();

  return addEntityToState({ editorState, setEditorState, url, linkText, decorator });
};
