import React from 'react';
import { BsListUl, BsListOl, BsLink45Deg } from 'react-icons/bs';

const toggles = {
  normal: ({ RichUtils, editorState }) => RichUtils.toggleBlockType(editorState, 'unstyled'),
  'header-one': ({ RichUtils, editorState }) =>
    RichUtils.toggleBlockType(editorState, 'header-one'),
  'header-two': ({ RichUtils, editorState }) =>
    RichUtils.toggleBlockType(editorState, 'header-two'),
  'header-three': ({ RichUtils, editorState }) =>
    RichUtils.toggleBlockType(editorState, 'header-three'),
  bold: ({ RichUtils, editorState }) => RichUtils.toggleInlineStyle(editorState, 'BOLD'),
  italic: ({ RichUtils, editorState }) => RichUtils.toggleInlineStyle(editorState, 'ITALIC'),
  underline: ({ RichUtils, editorState }) => RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'),
  'unordered-list-item': ({ RichUtils, editorState }) =>
    RichUtils.toggleBlockType(editorState, 'unordered-list-item'),
  'ordered-list-item': ({ RichUtils, editorState }) =>
    RichUtils.toggleBlockType(editorState, 'ordered-list-item'),
  link: ({ RichUtils, editorState, setOpenLinkModal, openLinkModal }) => {
    setOpenLinkModal(!openLinkModal);

    return RichUtils.toggleBlockType(editorState, 'unstyled');
  },
};

const toggleStyle = ({ style, ...props }) => {
  const { setEditorState } = props;

  return setEditorState(toggles[style](props));
};

const isCurrentInlineStyle = ({ editorState, style }) => {
  if (!editorState) return null;

  return editorState.getCurrentInlineStyle().has(style);
};

const isCurrentBlock = ({ editorState, blockType }) => {
  if (!editorState) return null;
  const selection = editorState.getSelection();
  const currentBlockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return currentBlockType === blockType;
};

const editorButtons = [
  {
    name: 'normal',
    clickAction: (props) => toggleStyle({ style: 'normal', ...props }),
    style: '',
    display: 'Normal text',
    isActive: () => {},
  },
  {
    name: 'h1',
    clickAction: (props) => toggleStyle({ style: 'header-one', ...props }),
    style: '',
    display: 'H1',
    isActive: ({ editorState }) => {
      return isCurrentBlock({ editorState, blockType: 'header-one' });
    },
  },
  {
    name: 'h2',
    clickAction: (props) => toggleStyle({ style: 'header-two', ...props }),
    style: '',
    display: 'H2',
    isActive: ({ editorState }) => {
      return isCurrentBlock({ editorState, blockType: 'header-two' });
    },
  },
  {
    name: 'h3',
    clickAction: (props) => toggleStyle({ style: 'header-three', ...props }),
    style: '',
    display: 'H3',
    isActive: ({ editorState }) => {
      return isCurrentBlock({ editorState, blockType: 'header-three' });
    },
  },
  {
    name: 'bold',
    clickAction: (props) => toggleStyle({ style: 'bold', ...props }),
    style: 'font-semibold',
    display: 'B',
    isActive: ({ editorState }) => {
      return isCurrentInlineStyle({ editorState, style: 'BOLD' });
    },
  },
  {
    name: 'italic',
    clickAction: (props) => toggleStyle({ style: 'italic', ...props }),
    style: 'italic pl-1',
    display: 'i',
    isActive: ({ editorState }) => {
      return isCurrentInlineStyle({ editorState, style: 'ITALIC' });
    },
  },
  {
    name: 'underline',
    clickAction: (props) => toggleStyle({ style: 'underline', ...props }),
    style: 'underline',
    display: 'U',
    isActive: ({ editorState }) => {
      return isCurrentInlineStyle({ editorState, style: 'UNDERLINE' });
    },
  },
  {
    name: 'unorderedList',
    clickAction: (props) => toggleStyle({ style: 'unordered-list-item', ...props }),
    style: 'text-lg',
    display: <BsListUl />,
    isActive: ({ editorState }) => {
      return isCurrentBlock({ editorState, blockType: 'unordered-list-item' });
    },
  },
  {
    name: 'orderedList',
    clickAction: (props) => toggleStyle({ style: 'ordered-list-item', ...props }),
    style: 'text-lg',
    display: <BsListOl />,
    isActive: ({ editorState }) => {
      return isCurrentBlock({ editorState, blockType: 'ordered-list-item' });
    },
  },
  {
    name: 'link',
    clickAction: (props) => toggleStyle({ style: 'link', ...props }),
    style: 'text-lg',
    display: <BsLink45Deg />,
    isActive: () => {},
  },
];

export default editorButtons;
