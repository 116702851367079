import React, { useReducer, createContext, useContext, ReactNode } from 'react';
import { BriefStatus, FieldBriefs, Brief } from '@schema';
import { SortField, SortDirection } from 'flex/Table/table.type';
import { SortField as SortFieldBriefs } from '@schema';
import briefsReducer from './briefsReducer';

export const NO_BRIEFS_FETCHED = -1;

type paramsType = {
  offset: number;
  limit: number;
};

export type FiltersType = {
  keyword: string;
  status: BriefStatus[];
  contact: string | null;
  campaigns: string | null;
  salesPointGroupIds: [];
  salesPointIds: [];
};

export type InitialStateType = {
  isArchivedDisplayed: boolean;
  asLayout: boolean;
  noHeader: boolean;
  isProvider: boolean | null;
  canCreateAndUpdateBriefs: boolean | null;
  showFilters: boolean;
  isFirstBriefsFetched: boolean;
  isAbandonModalOpen: { isOpen: boolean; briefId: string };

  filters: FiltersType;
  isDefaultFilters: boolean;
  paramsActivated: paramsType;
  currentPageActivated: number;
  totalActivatedBriefsWithFilters: number;
  totalActivatedBriefsWithoutFilters: number;

  paramsArchived: paramsType;
  currentPageArchived: number;
  totalArchivedBriefsWithFilters: number;
  totalArchivedBriefsWithoutFilters: number;
  isDisplayingBriefsInListMode?: boolean;
  sortField?: SortFieldBriefs;
};

export const defaultInitialState: InitialStateType = {
  isArchivedDisplayed: false,
  asLayout: true,
  noHeader: false,
  isProvider: null,
  canCreateAndUpdateBriefs: null,
  showFilters: false,
  isFirstBriefsFetched: false,
  isAbandonModalOpen: { isOpen: false, briefId: '' },

  filters: {
    keyword: '',
    status: [],
    contact: null,
    campaigns: null,
    salesPointGroupIds: [],
    salesPointIds: [],
  },
  paramsActivated: {
    offset: 0,
    limit: 10,
  },
  currentPageActivated: 1,
  isDefaultFilters: true,
  totalActivatedBriefsWithFilters: NO_BRIEFS_FETCHED,
  totalActivatedBriefsWithoutFilters: NO_BRIEFS_FETCHED,

  paramsArchived: {
    offset: 0,
    limit: 10,
  },
  currentPageArchived: 1,
  totalArchivedBriefsWithFilters: NO_BRIEFS_FETCHED,
  totalArchivedBriefsWithoutFilters: NO_BRIEFS_FETCHED,
  isDisplayingBriefsInListMode: true,
  sortField: { field: FieldBriefs.CreatedAt, typeOfSort: SortDirection.DESC }
};

export type Action =
  | {
      type: 'SET_TOTAL_BRIEFS_WITH_FILTERS';
      payload: {
        totalActivatedBriefsWithFilters: number;
        totalArchivedBriefsWithFilters: number;
      };
    }
  | {
      type: 'SET_TOTAL_BRIEFS_WITHOUT_FILTERS';
      payload: {
        totalActivatedBriefsWithoutFilters: number;
        totalArchivedBriefsWithoutFilters: number;
      };
    }
  | { type: 'SET_FILTER'; payload: Partial<FiltersType> }
  | { type: 'SET_SHOW_FILTERS' }
  | { type: 'SET_IS_ARCHIVED_DISPLAYED'; payload: boolean }
  | { type: 'SET_PAGE'; payload: number }
  | { type: 'SET_ABANDON_MODAL'; payload: { isOpen: boolean; briefId: string } }
  | { type: 'SET_ROLES'; payload: { isProvider: boolean; canCreateAndUpdateBriefs: boolean } }
  | { type: 'SET_CAMPAIGN_FILTER'; payload: string }
  | { type: 'SET_LIMIT'; payload: number }
  | { type: 'SET_LAYOUT'; payload: boolean }
  | { type: 'SET_NO_HEADER'; payload: boolean }
  | { type: 'SET_DISPLAY_BRIEFS_IN_LIST_MODE'; payload: boolean }
  | { type: 'SET_SORT_FIELD'; payload: SortFieldBriefs };

const BriefsStateContext = createContext<InitialStateType>(defaultInitialState);
const BriefsDispatchContext = createContext<React.Dispatch<Action>>(() => {});

export function BriefsProvider({
  children,
  initialStateOverWritten = defaultInitialState,
}: {
  children: ReactNode;
  initialStateOverWritten?: InitialStateType;
}) {
  const [state, dispatch] = useReducer<React.Reducer<InitialStateType, Action>>(briefsReducer, {
    ...defaultInitialState,
    ...initialStateOverWritten,
  });

  return (
    <BriefsStateContext.Provider value={state}>
      <BriefsDispatchContext.Provider value={dispatch}>{children}</BriefsDispatchContext.Provider>
    </BriefsStateContext.Provider>
  );
}

export function useBriefsState() {
  return useContext(BriefsStateContext);
}

export function useBriefsDispatch() {
  return useContext(BriefsDispatchContext);
}
