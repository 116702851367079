import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const { sentryDsn } = publicRuntimeConfig;

const useSentryContext = (userId, clientId, role, email) => {
  // No DSN means Sentry is not activated
  useEffect(() => {
    if (!sentryDsn) return;

    Sentry.setUser({
      id: userId,
      email,
    });
    Sentry.setTag('client_id', clientId);
    Sentry.setTag('role', role);
  }, [userId, clientId, email, role]);
};

export default useSentryContext;
