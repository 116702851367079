import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import PaginationWrapper from './PaginationWrapper';

export default function Pagination({
  className,
  params,
  setParams,
  pageCount,
  inputSearch,
  activeFilters,
  resetScrollOnPageChange,
}) {
  const getActivePageParams = () => (params.offset ? params.offset / params.limit : 0);
  const [activePage, setActivePage] = useState(getActivePageParams);

  useEffect(() => {
    if (inputSearch !== '' || activeFilters.length) {
      setActivePage(getActivePageParams());
    }
  }, [inputSearch, activeFilters]);
  useEffect(() => {
    setActivePage(getActivePageParams());
  }, [params.offset]);

  if (!pageCount) return null;

  return (
    <PaginationWrapper className={`pagination ${className}`}>
      <ReactPaginate
        breakLabel="..."
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={({ selected }) => {
          if (resetScrollOnPageChange) {
            // scroll #main to top smoothly on pagination change
            document.getElementById('main')?.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }

          setParams({ ...params, offset: selected * params.limit });
          setActivePage(selected);
        }}
        forcePage={activePage}
        containerClassName="pagination"
        activeClassName="active"
        previousClassName="disabled"
        nextClassName="disabled"
        initialPage={activePage}
      />
    </PaginationWrapper>
  );
}

Pagination.defaultProps = {
  className: '',
  inputSearch: '',
  activeFilters: [],
  resetScrollOnPageChange: false,
};

Pagination.propTypes = {
  className: PropTypes.string,
  inputSearch: PropTypes.string,
  params: PropTypes.shape({
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
  }).isRequired,
  activeFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  setParams: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  resetScrollOnPageChange: PropTypes.bool,
};
